import { Analytics } from 'cliengo-fe-utils';
import { useReducer, useCallback } from 'react';

import { IAppTrigger, IMatch } from '../../utils/interfaces';

import { initialState, triggerReducer, ActionType } from './trigger.reducer';

const useTrigger = (receivedState: IAppTrigger) => {
  const [triggerState, triggerDispatch] = useReducer(
    triggerReducer,
    receivedState ?? initialState
  );

  const handleUpdateEnable = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      triggerDispatch({
        type: ActionType.UPDATE_STATUS,
        payload: { enabled: e.target.checked },
      });
      Analytics.trackEvent({
        eventName: 'switch_status',
        category: 'triggers',
        action: 'switch_status',
      });
    },
    []
  );

  const handleUpdateName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      triggerDispatch({
        type: ActionType.UPDATE_NAME,
        payload: { name: e.target.value },
      });
      Analytics.trackEvent({
        eventName: 'add_trigger_name',
        category: 'triggers',
        action: 'add_trigger_name',
      });
    },
    []
  );

  const handleUpdateResponse = useCallback((marked: string): void => {
    triggerDispatch({
      type: ActionType.UPDATE_RESPONSE,
      payload: { response: marked },
    });
  }, []);

  const handleUpdateTypeResponse = useCallback(
    (type: IAppTrigger['typeResponse']): void => {
      triggerDispatch({
        type: ActionType.UPDATE_TYPE_RESPONSE,
        payload: { typeResponse: type },
      });
      Analytics.trackEvent({
        eventName: 'add_robot_answer',
        category: 'triggers',
        action: 'add_robot_answer',
      });
    },
    []
  );

  const handleUpdateStopNextMessage = useCallback(
    (value: IAppTrigger['stopNextMessage']): void => {
      triggerDispatch({
        type: ActionType.UPDATE_STOP_NEXT_MESSAGE,
        payload: { stopNextMessage: value },
      });
      Analytics.trackEvent({
        eventName: 'pause_conversation',
        category: 'triggers',
        action: 'pause_conversation',
      });
    },
    []
  );

  const handleUpdateIgnoreLead = useCallback(
    (value: IAppTrigger['ignoreLead']): void => {
      triggerDispatch({
        type: ActionType.UPDATE_IGNORE_LEAD,
        payload: { ignoreLead: value },
      });
    },
    []
  );

  const handleAddNewMatch = useCallback((): void => {
    triggerDispatch({
      type: ActionType.ADD_NEW_MATCH,
      payload: { matches: [{ type: 'EQUALS', value: '' }] },
    });
  }, []);

  const handleDeleteMatch = useCallback((deleteMatchIndex: number): void => {
    triggerDispatch({
      type: ActionType.DELETE_MATCH,
      payload: {
        matches: [
          {
            position: deleteMatchIndex,
            type: 'EQUALS',
            value: '',
          },
        ],
      },
    });
  }, []);

  const handleUpdateMatchValue = useCallback(
    (
      value: string,
      matchToUpdatedIndex: number,
      currentType: IMatch['type']
    ): void => {
      triggerDispatch({
        type: ActionType.UPDATE_MATCH,
        payload: {
          matches: [
            {
              position: matchToUpdatedIndex,
              type: currentType,
              value: value,
            },
          ],
        },
      });
    },
    []
  );

  const handleUpdateMatchType = useCallback(
    (
      e: React.ChangeEvent<HTMLSelectElement>,
      matchToUpdatedIndex: number,
      currentValue: string
    ): void => {
      triggerDispatch({
        type: ActionType.UPDATE_MATCH,
        payload: {
          matches: [
            {
              position: matchToUpdatedIndex,
              type: e.target.value as IMatch['type'],
              value: currentValue,
            },
          ],
        },
      });
      Analytics.trackEvent({
        eventName: 'edit_match_type',
        category: 'triggers',
        action: 'edit_match_type',
      });
    },
    []
  );

  const handleUpdateAssignedToAdd = useCallback((assigned: string): void => {
    triggerDispatch({
      type: ActionType.UPDATE_ASSIGNED_TO_ADD,
      payload: { assignedTo: [assigned] },
    });
    Analytics.trackEvent({
      eventName: 'user_team_add',
      category: 'triggers',
      action: 'user_team_add',
    });
  }, []);

  const handleUpdateAssignedToRemove = useCallback(
    (assignedToRemove: string): void => {
      triggerDispatch({
        type: ActionType.UPDATE_ASSIGNED_TO_REMOVE,
        payload: { assignedTo: [assignedToRemove] },
      });
      Analytics.trackEvent({
        eventName: 'user_team_remove',
        category: 'triggers',
        action: 'user_team_remove',
      });
    },
    []
  );

  const handleAddNewOption = useCallback((option: string): void => {
    triggerDispatch({
      type: ActionType.ADD_NEW_OPTION,
      payload: { responseOptions: [option] },
    });
    Analytics.trackEvent({
      eventName: 'add_response_variant',
      category: 'triggers',
      action: 'add_response_variant',
    });
  }, []);

  const handleRemoveOption = useCallback((option: string): void => {
    triggerDispatch({
      type: ActionType.DELETE_OPTION,
      payload: { responseOptions: [option] },
    });
  }, []);

  const handleResetOptions = useCallback((): void => {
    triggerDispatch({
      type: ActionType.RESET_OPTIONS,
      payload: { responseOptions: [] },
    });
  }, []);

  const handleSetSaved = useCallback((): void => {
    triggerDispatch({
      type: ActionType.SET_SAVED,
      payload: { hasUnsaveChanges: false },
    });
  }, []);

  const handleResetTrigger = useCallback((): void => {
    triggerDispatch({
      type: ActionType.RESET_TRIGGER,
      payload: initialState,
    });
  }, []);

  return {
    triggerState,
    handleUpdateName,
    handleUpdateEnable,
    handleUpdateResponse,
    handleUpdateTypeResponse,
    handleUpdateIgnoreLead,
    handleUpdateStopNextMessage,
    handleAddNewMatch,
    handleDeleteMatch,
    handleUpdateMatchValue,
    handleUpdateMatchType,
    handleUpdateAssignedToAdd,
    handleUpdateAssignedToRemove,
    handleAddNewOption,
    handleRemoveOption,
    handleResetOptions,
    handleSetSaved,
    handleResetTrigger,
  };
};

export default useTrigger;
