import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILayoutState {
  loading: boolean;
  error: boolean;
  modeHours: 'business_hours' | 'offline_mode';
}

const initialState: ILayoutState = {
  loading: false, // TODO: volver a establecer el valor inicial en false
  error: false,
  modeHours: 'business_hours',
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLoading: (state: ILayoutState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: ILayoutState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    toggleLoading: (state: ILayoutState) => {
      state.loading = !state.loading;
    },
    setModeHours: (
      state: ILayoutState,
      action: PayloadAction<'business_hours' | 'offline_mode'>
    ) => {
      state.modeHours = action.payload;
    },
  },
});

export const layoutActions = layoutSlice.actions;

export default layoutSlice.reducer;
