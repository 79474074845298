import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { ITriggersList, IAppTrigger } from '../../../utils/interfaces';

import { deleteTrigger } from '@/api/deleteTrigger';

interface IDeleteTriggerData {
  siteId: string;
  triggerId: IAppTrigger['id'];
}

const deleteTriggerAction = createAsyncThunk(
  'trigger/deleteTrigger',
  async ({ siteId, triggerId }: IDeleteTriggerData) => {
    const response = await deleteTrigger(siteId, triggerId);
    return response;
  }
);

export const deleteTriggerReducer = (
  state: ITriggersList,
  { payload }: PayloadAction<IAppTrigger['id']>
) => {
  const updatedResults = state.results.filter(
    (trigger) => trigger.id !== payload
  );
  return { ...state, results: updatedResults, status: 'succeeded' };
};

export default deleteTriggerAction;
