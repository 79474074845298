import { URLS } from '../utils/constants';

import { fetch } from '@/utils/fetch';
import { IAppTrigger } from '@/utils/interfaces';
import { IAppWebsiteData } from '@/adapters/websiteDataAdapter';

export const deleteTrigger = async (
  siteId: IAppWebsiteData['siteId'],
  triggerId: IAppTrigger['id']
): Promise<string> => {
  const data = '';

  const config = {
    method: 'delete',
    url: `${URLS.API_URL}/sites/${siteId ?? ''}/chatbot/triggers/${
      triggerId ?? ''
    }`,
    headers: {},
    data: data,
  };

  try {
    await fetch(config);
    return triggerId ?? '';
  } catch (error) {
    throw new Error('Failed to delete trigger');
  }
};
