import { isEmpty, isOnlySpaces } from './fieldValidators';

import { IFieldErrorProps } from '@/components/InputControl/InputControl.types';
import {
  MATCH_VALUE_CHAR_LIMIT,
  MATCH_VALUE_CHAR_MINIMUM,
} from '@/utils/constants';

const validateMatchValue = (
  inputValue: string,
  messages: { [key: string]: string } = {}
): IFieldErrorProps => {
  if (isEmpty(inputValue) || isOnlySpaces(inputValue)) {
    return { hasError: true, errorMessage: messages.noEmpty };
  }
  if (inputValue.trim().length < MATCH_VALUE_CHAR_MINIMUM) {
    return { hasError: true, errorMessage: messages.tooShort };
  }
  if (inputValue.trim().length > MATCH_VALUE_CHAR_LIMIT) {
    return { hasError: true, errorMessage: messages.tooLong };
  }
  return { hasError: false, errorMessage: '' };
};
export { validateMatchValue };
