import { IPaging, ITrigger } from '@/utils/interfaces';
import { URLS } from '@/utils/constants';
import { fetch } from '@/utils/fetch';
import { IAppTriggersData } from '@/adapters/triggerDataAdapter';
import { IAppWebsiteData } from '@/adapters/websiteDataAdapter';

export interface ITriggersDataResponse {
  paging: IPaging;
  results: Partial<ITrigger>[];
}

export interface DataResponse {
  data: ITriggersDataResponse;
}

export async function getTriggersData(
  siteId: IAppWebsiteData['siteId']
): Promise<IAppTriggersData> {
  try {
    const { data: triggersData } = (await fetch({
      baseURL: URLS.API_URL,
      url: `/sites/${siteId}/chatbot/triggers`,
    })) as unknown as DataResponse;

    return triggersData;
  } catch (error) {
    throw new Error('Failed to fetch triggers data');
  }
}

export default getTriggersData;
