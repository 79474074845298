import { PayloadAction } from '@reduxjs/toolkit';

import { IAppChatbotConfigData } from '@/adapters/chatbotConfigAdapter';

export const getChatbotReducer = (
  state: IAppChatbotConfigData,
  { payload }: PayloadAction<IAppChatbotConfigData>
) => ({
  ...state,
  ...payload,
  status: 'succeeded',
});
