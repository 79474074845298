import { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import TriggersView from '@/views/Triggers/Triggers.view';
import InitialDataChecker from '@/components/InitialDataChecker';

export const App: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/triggers/:siteId"
        element={
          <InitialDataChecker>
            <TriggersView />
          </InitialDataChecker>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);
