import { ITrigger, IAppTrigger } from '@/utils/interfaces';
import { ITriggersDataResponse } from '@/api/get-triggers';
import typeResponse from '@/utils/helpers/typeResponse';

export const triggerModelForApp: IAppTrigger = {
  id: '',
  response: '',
  name: '',
  stopNextMessage: false,
  ignoreLead: false,
  matches: [],
  enabled: true,
  assignedTo: [],
  responseOptions: [],
  hasUnsaveChanges: false,
  typeResponse: 'text',
  isSmartTrigger: false,
};

const singleDtoTrigger = (trigger: Partial<ITrigger>): IAppTrigger => {
  const itemToMap: Partial<ITrigger> = { ...trigger };
  for (const key in triggerModelForApp) {
    if (!itemToMap.hasOwnProperty(key)) {
      itemToMap[key as keyof ITrigger] =
        triggerModelForApp[key as keyof IAppTrigger];
    }
  }
  return {
    ...(trigger as ITrigger),
    ...(itemToMap as IAppTrigger),
    typeResponse: typeResponse(
      itemToMap.responseOptions as ITrigger['responseOptions']
    ),
  };
};

const multipleDtoTriggersList = (dataResponse: ITriggersDataResponse) => {
  const { results } = dataResponse;

  const mappedResults = results.map((trigger: Partial<ITrigger>) => {
    return singleDtoTrigger(trigger);
  });

  const formattedResponse = {
    ...dataResponse,
    results: mappedResults,
  };

  return formattedResponse;
};

export { singleDtoTrigger, multipleDtoTriggersList };
