import { IPaging, IAppTrigger } from '../utils/interfaces';

import { multipleDtoTriggersList } from '@/utils/mappers/triggerDtoApp.mapper';
// eslint-disable-next-line import/no-named-as-default
import getTriggersData from '@/api/get-triggers';

export interface IAppTriggersData {
  paging: IPaging;
  results: Partial<IAppTrigger>[];
}

export async function triggerDataAdapter(
  siteId: string
): Promise<IAppTriggersData> {
  try {
    const triggersData = await getTriggersData(siteId);
    const { paging, results } = multipleDtoTriggersList(triggersData);
    return {
      paging,
      results,
    };
  } catch (error) {
    throw new Error('Failed to adapter triggers data');
  }
}
