import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import {
  userDataAdapter,
  usersListDataAdapter,
  IAppUserData,
} from '../../adapters/userDataAdapter';

export const getUser = createAsyncThunk('user/getUser', async () => {
  const response: IAppUserData = await userDataAdapter();
  return response;
});

export const getUsersList = createAsyncThunk(
  'usersList/getUsersList',
  async () => {
    const response: IAppUserData = await usersListDataAdapter();
    return response;
  }
);

const initialState: IAppUserData = {
  user: {
    id: '',
    companyId: '',
    isSuperUser: false,
    language: '',
    labs: {},
  },
  usersList: {
    users: [],
    teams: [],
  },
  status: 'idle',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state: IAppUserData) => {
      state.status = 'pending';
    });
    builder.addCase(
      getUser.fulfilled,
      (state: IAppUserData, { payload }: PayloadAction<IAppUserData>) => ({
        ...state,
        user: payload.user,
        status: 'succeeded',
      })
    );
    builder.addCase(getUser.rejected, (state: IAppUserData) => {
      state.status = 'failed';
    });
    builder.addCase(getUsersList.pending, (state: IAppUserData) => {
      state.status = 'pending';
    });
    builder.addCase(
      getUsersList.fulfilled,
      (state: IAppUserData, { payload }: PayloadAction<IAppUserData>) => ({
        ...state,
        usersList: payload.usersList,
        status: 'succeeded',
      })
    );
    builder.addCase(getUsersList.rejected, (state: IAppUserData) => {
      state.status = 'failed';
    });
  },
});

export default userSlice.reducer;
