import {
  chakra,
  Box,
  Container,
  Grid,
  GridItem,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';

import { StyledSmartSuggestion } from './SmartSuggestion.styles';
import { ISmartSuggestionProps } from './SmartSuggestion.types';

import { translate } from '@/utils/i18n/translate';
import setHighlight from '@/utils/helpers/setHighlight';
import { useAppSelector } from '@/hooks/useRedux/redux';

const SmartSuggestion = ({
  id,
  samples,
  term,
  onAddSuggestion,
  onIgnoreSuggestion,
}: ISmartSuggestionProps) => {
  const { selectedSuggestionId } = useAppSelector(
    (state) => state.smartSuggestions
  );
  const toast = useToast();

  const handleAddSmartSuggetion = () => {
    if (selectedSuggestionId) {
      toast({
        title: translate('smart_triggers.error_suggestion_already_selected'),
        status: 'error',
        isClosable: true,
        containerStyle: {
          maxWidth: '500px',
        },
      });
      return;
    }
    onAddSuggestion(id);
  };

  const handleIgnoreSmartSuggetion = () => {
    onIgnoreSuggestion({ id, term, samples });
  };

  return (
    <StyledSmartSuggestion>
      <Box mr={2} ml={2} mt={5} mb={5} className="sidebar-card">
        <Container
          backgroundColor="#FFFFFF"
          p="4"
          border={'1px dashed #000000;'}
          borderRadius={'15px'}
        >
          <Text mt={1}>
            {translate('smart_triggers.reply_to')}{' '}
            <chakra.strong bg="yellow">
              {term.split(' ').map((elem: string, i: number) => (
                <span key={`term-${id}-${i}`}>{`${elem} `}</span>
              ))}
            </chakra.strong>
          </Text>
          {samples && (
            <Text
              fontSize={'14px'}
              dangerouslySetInnerHTML={{
                __html: setHighlight(term, samples[0]),
              }}
            />
          )}
          <Grid mb={1} mt={5} templateColumns="repeat(5, 3fr)">
            <GridItem colSpan={4}>
              <Button
                bg={'#FFFFFF'}
                borderRadius={'16px'}
                border={'1px solid #E5E5E5'}
                textColor={'#000000'}
                fontWeight={'400'}
                _hover={{ bg: '#FFFFFF' }}
                size={'sm'}
                m={'0'}
                float={'right'}
                onClick={handleIgnoreSmartSuggetion}
              >
                {translate('smart_triggers.ignore')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                float={'right'}
                bgColor={'#1ABC9C'}
                borderRadius={'16px'}
                _hover={{ bg: '#1ABC9C' }}
                ml={'10px'}
                onClick={handleAddSmartSuggetion}
              >
                {translate('smart_triggers.add')}
              </Button>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </StyledSmartSuggestion>
  );
};

export default SmartSuggestion;
