import { URLS } from '@/utils/constants';
import { fetch } from '@/utils/fetch';

interface IUserData {
  id: string;
  companyId: string;
  isSuperUser: boolean;
  language: string;
  labs: {
    [key: string]: string;
  };
}

interface IUserDataResponse {
  data: IUserData;
}

export interface IUserStoreData {
  user: IUserData;
}

export interface IUsersItemListData {
  id: string;
  name: string;
  companyId: string;
  index?: number;
}

export interface IUsersListData {
  users: IUsersItemListData[];
  teams: IUsersItemListData[];
}

interface IUsersListDataResponse {
  data: { paging: { total: string }; results: IUsersItemListData[] };
}

export interface IUsersListStoreData {
  usersList: IUsersListData;
}

export interface IAppUserData {
  user: IUserData;
  usersList: IUsersListData;
  status?: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export async function userDataAdapter() {
  try {
    const { data: userData } = (await fetch({
      baseURL: URLS.API_URL,
      url: '/users/me',
    })) as unknown as IUserDataResponse;
    const { companyId, id, isSuperUser, labs, language } = userData;
    return {
      user: {
        id: id,
        companyId: companyId,
        isSuperUser: isSuperUser,
        language: language,
        labs: labs,
      },
    };
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}

export const usersListDataAdapter = async () => {
  try {
    const { data: usersData } = (await fetch({
      baseURL: URLS.API_URL,
      url: '/users',
    })) as unknown as IUsersListDataResponse;
    const { data: teamsData } = (await fetch({
      baseURL: URLS.API_URL,
      url: '/teams',
    })) as unknown as IUsersListDataResponse;

    return {
      usersList: {
        users: usersData.results.map((item) => {
          const { companyId, id, name } = item;

          return {
            id,
            name,
            companyId,
          };
        }),
        teams: teamsData.results.map((item) => {
          const { companyId, id, name } = item;
          return {
            id,
            name,
            companyId,
          };
        }),
      },
      success: true,
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
    };
  }
};
