import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAppSmartSuggestion } from '../smart-suggestions.types';

import discardSmartSuggestionData from '@/api/reject-smart-suggestion';

interface IRejectSmartSuggestion {
  siteId: string;
  suggestion: IAppSmartSuggestion;
}

const rejectSmartSuggestionAction = createAsyncThunk(
  'trigger/rejectSmartSuggestion',
  async ({ siteId, suggestion }: IRejectSmartSuggestion) => {
    const response = await discardSmartSuggestionData(siteId, suggestion);
    return response;
  }
);

export default rejectSmartSuggestionAction;
