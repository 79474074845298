import { ChakraProps, Square, chakra } from '@chakra-ui/react';

interface ActionButtonProps extends ChakraProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
  icon?: string;
}

export const ActionButton = ({
  children,
  onClick,
  id,
  icon,
  ...props
}: ActionButtonProps) => (
  <Square
    onClick={onClick}
    id={id}
    cursor="pointer"
    transition="all .4s ease"
    as="button"
    _hover={{
      bg: 'gray.100',
    }}
    size="2rem"
    rounded="md"
    display="flex"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    {...props}
  >
    {icon && (
      <chakra.i cursor="pointer" onClick={onClick} className={`icon ${icon}`} />
    )}
    {children}
  </Square>
);
