/**
 * All rules validation
 */
const multipleFieldValidations = (rules: object) => {
  return Object.values(rules).every((item) => item);
};

/**
 * Check to confirm that feild is empty
 */
const isEmpty = (value: string) => {
  return value.trim() === '';
};

const isOnlySpaces = (str: string) => {
  return !/\S/.test(str);
};

// Check if an object is empty
const isEmptyObj = (obj: object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Check if field value respects the minimum length required
 */
const hasMinimumLength = (value: string, lengthRule: number) => {
  return value.trim().length < lengthRule;
};

const isStringValueEqual = (value: string, valueToCompare: string) => {
  return value.trim().toUpperCase() === valueToCompare.trim().toUpperCase();
};

/**
 * Regexp validation
 */
const regexpValidator = (type: string, value: string, regexp: RegExp) => {
  const re = new RegExp(regexp);
  let valueFiltered;
  if (type === 'email') {
    valueFiltered = value;
  } else {
    valueFiltered = value
      .split('')
      .filter((elem) => elem.match(/(\d+)/g))
      .join('');
  }

  return re.test(valueFiltered);
};

const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_TELEFONO = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[- s/0-9]*$/;

export {
  isEmptyObj,
  regexpValidator,
  hasMinimumLength,
  isEmpty,
  isOnlySpaces,
  multipleFieldValidations,
  isStringValueEqual,
  REGEX_EMAIL,
  REGEX_TELEFONO,
};
