/* eslint-disable */
import { createAsyncThunk, PayloadAction, nanoid } from '@reduxjs/toolkit';

import {
  IAppSmartSuggestions,
  IDataSuggestions,
} from '../smart-suggestions.types';

import getSmartSuggestionsData from '@/api/get-smart-suggestions';

const getSmartSuggestionsAction = createAsyncThunk(
  'smartSuggestions/getSmartSuggestionsAction',
  async (siteId: string): Promise<IAppSmartSuggestions> => {
    const { bigrams }: IDataSuggestions = await getSmartSuggestionsData(siteId);

    const mappedSuggestions = bigrams.map((smartSuggestion) => {
      return {
        id: nanoid(),
        samples: smartSuggestion.samples,
        term: smartSuggestion.term,
      };
    });

    return mappedSuggestions;
  }
);

export const getSmartSuggestionsReducer = (
  state: IAppSmartSuggestions,
  { payload }: PayloadAction<IAppSmartSuggestions>
) => ({
  ...state,
  ...payload,
});

export default getSmartSuggestionsAction;
