import { URLS } from '../utils/constants';

import { fetch } from '@/utils/fetch';

export type Priority =
  | ['INTERVENED', 'TRIGGER', 'ROUND_ROBIN', 'QUESTION']
  | ['TRIGGER', 'INTERVENED', 'ROUND_ROBIN', 'QUESTION'];

interface DataResponseAssignPriority {
  data: {
    assign_priority: Priority;
  };
}

const updateAssignPriority = async (
  siteId: string,
  dataPriority: Priority
): Promise<Priority> => {
  const config = {
    method: 'patch',
    url: `${URLS.API_URL}/projects/${siteId}/chatbots/${siteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: dataPriority,
  };

  try {
    const { data: AssignPriorityUpdated } = (await fetch(
      config
    )) as unknown as DataResponseAssignPriority;
    return AssignPriorityUpdated.assign_priority;
  } catch (error) {
    throw new Error('Failed to edit website');
  }
};

export default updateAssignPriority;
