import styled from 'styled-components';

export const StyledEditor = styled('div')`
  & .editor-inner {
    height: 150px;
    max-height: 150px;
    overflow-y: auto;
  }
`;

export const StyledEditorErrorWrapper = styled('div')`
  height: 1.25rem;
  margin-top: 0.5rem;
  padding: 0 10px;
`;
