import { VStack, Text, Select } from '@chakra-ui/react';

import { translate } from '@/utils/i18n/translate';

export interface IOptionAssignament {
  id: string;
  label: string;
  value: string;
}

interface IAssignmentPriorityProps {
  label: string;
  options: IOptionAssignament[];
  currentSelection: IOptionAssignament;
  onAssign: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ArrowDropDown = () => (
  <svg
    width="11"
    height="5"
    viewBox="0 0 11 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.654175 0L5.65417 5L10.6542 0H0.654175Z" fill="#515151" />
  </svg>
);

const AssignmentPriority = ({
  label,
  currentSelection,
  options,
  onAssign,
}: IAssignmentPriorityProps): JSX.Element => {
  return (
    <VStack
      mx={{
        base: 6,
        lg: 12,
      }}
      pb={12}
      mt="4"
      alignItems="start"
    >
      <Text data-testid="subtitle_triggers" fontSize="md" py="2">
        {label}
      </Text>
      <Select
        data-testid="select_triggers"
        w="100%"
        maxW="320px"
        borderColor="#B0B0B0"
        bg="white"
        icon={<ArrowDropDown />}
        id="assignmentPriority"
        name="assignmentPriority"
        value={currentSelection.value}
        onChange={onAssign}
      >
        {options &&
          options.map((option) => (
            <option
              data-testid={`option-key-${option.id}`}
              key={`option-key-${option.id}`}
              value={option.value}
            >
              {translate(`assignment_priority_options.${option.id}`)}
            </option>
          ))}
      </Select>
    </VStack>
  );
};

export default AssignmentPriority;
