const setHighlight = (term: string, sample: string): string => {
  const splittedTerm = term.split(' ');
  let highlights = sample;
  splittedTerm.forEach((term) => {
    const regex = new RegExp(`\\b${term}\\b`, 'g');
    highlights = highlights
      .split(regex)
      .join(
        `<span style="background-color:#FFFF00">${term.toLowerCase()}</span>`
      );
  });

  return highlights;
};

export default setHighlight;
