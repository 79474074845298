enum Envs {
  development = 'development',
  stage = 'stage',
  production = 'production',
}

enum Urls {
  LOGIN = 'LOGIN',
  API_URL = 'API_URL',
  AWS_API = 'AWS_API',
  CORE_APP_URL = 'CORE_APP_URL',
  WEBOPTIMIZER_URL = 'WEBOPTIMIZER_URL',
  SOCKETEER = 'SOCKETEER',
  LIVE = 'LIVE',
  CRM = 'CRM',
  LW = 'LW',
}

export type URLS_EACH_ENV = {
  [key in Urls]: string;
};

export type ALL_URLS = {
  [key in Envs]: URLS_EACH_ENV;
};

export const env: string = process.env.REACT_APP_ENV || 'development';
export const notEditable = [
  'envios_action_success',
  'envios_action_failed',
  'chat_whatsapp_derivacion',
  'email_order',
];
export const offlineEditable = [
  'salute',
  'selected_option',
  'final_salutation',
];
export const ANY_STRING_CHAR_MINIMUM = 1;
export const MATCH_VALUE_CHAR_LIMIT = 20;
export const MATCH_VALUE_CHAR_MINIMUM = 4;
export const TYPE_BUTTON_LIMIT_OPTIONS = 12;
export const cantUpdateInternalName = [
  'asesor',
  'asesor_whatsapp',
  'main',
  'salute',
  'selected_option',
  'final_salutation',
  'final_question',
  'contact_document_number',
  'contact_time',
  'email',
  'phone',
  'email_order',
];
export const hours = {
  business_hours: 'business_hours',
  offline_mode: 'offline_mode',
};
export const slugifyConfig = {
  replacement: '_',
  lower: true,
  trim: true,
  locale: 'en',
  remove: /[^\w\s]/g,
};
const URLS_SET: ALL_URLS = {
  development: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    AWS_API: 'https://ml.cliengo.com',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'localhost:3030/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
  },
  stage: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    AWS_API: 'https://ml.cliengo.com',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'https://live.stagecliengo.com/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
  },
  production: {
    LOGIN: 'https://www.login.cliengo.com',
    API_URL: 'https://api.cliengo.com/1.0',
    AWS_API: 'https://ml.cliengo.com',
    CORE_APP_URL: 'https://app.cliengo.com',
    WEBOPTIMIZER_URL: 'https://s.cliengo.com/weboptimizer',
    SOCKETEER: 'wss://socketeer.cliengo.com/operators',
    LIVE: 'https://live.cliengo.com/live',
    CRM: 'https://crm.cliengo.com',
    LW: 'https://lw.cliengo.com',
  },
};
export const URLS: URLS_EACH_ENV = URLS_SET[env as keyof ALL_URLS];

export const CRM_EVENTS = {
  GO_TO_CONFIGURATION: 'crm-redirect-to-configuration',
  GO_TO_PLANS: 'crm-redirect-to-plans',
  SET_UNSAVED: 'crm-register-unsaved-changes',
  CLOSE_MODAL: 'crm-close-modal',
  OPEN_RESTORE_MODAL: 'crm-open-restore-modal',
  OPEN_SCHEDULES_MODAL: 'crm-open-schedules-modal',
};

export const ASSIGNMENT_PRIORITY_OPTIONS = [
  { id: 'trigger', label: 'Respuestas automáticas', value: 'TRIGGER' },
  { id: 'intervened', label: 'Cliengo Live', value: 'INTERVENED' },
];
