/* eslint-disable @typescript-eslint/no-unsafe-return */
import es from './es';
import en from './en';
import pt from './pt';

const files = { es, en, pt };

let laguange: string;

export const initTranslation = (lang = 'es'): void => {
  const acceptedLangs = Object.keys(files);
  const userLang = lang.split('_')[0];
  laguange = acceptedLangs.includes(userLang) ? userLang : laguange;
};

export const translate = (path: string, separator = '.'): string => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return [...properties].reduce(
    (prev: keyof typeof files, curr: string) =>
      prev && prev[curr as keyof typeof prev],
    files[laguange as keyof typeof files]
  );
};
