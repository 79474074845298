import { useEffect, useState } from 'react';
import {
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Box,
  Text,
  useConst,
  Radio,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import { TypeResponseProps } from './typeResponse.types';
import { StyledTypeResponse } from './typeResponse.styles';

import { translate } from '@/utils/i18n/translate';
import {
  isEmpty,
  isStringValueEqual,
} from '@/utils/validators/fieldValidators';
import { validateOption } from '@/utils/validators/responseTypeValidators';
import {
  MATCH_VALUE_CHAR_LIMIT,
  MATCH_VALUE_CHAR_MINIMUM,
} from '@/utils/constants';

interface IFieldErrorProps {
  hasError: boolean;
  typeError: string | null;
  errorMessage: string;
}

const TypeResponse = ({
  triggerId,
  typeResponse,
  responseOptions,
  companyType,
  onAddNewOption,
  onRemoveOption,
  onUpdateTypeResponse,
}: TypeResponseProps) => {
  const [optionValue, setOptionValue] = useState<string>('');
  const [error, setError] = useState<IFieldErrorProps>({
    hasError: false,
    typeError: null,
    errorMessage: '',
  });

  const validationMessages = useConst(() => ({
    noEmpty: translate('lead.validation_messages.typeResponse.noEmpty'),
    isDuplicated: translate(
      'lead.validation_messages.typeResponse.isDuplicated'
    ),
    limitOptions: translate(
      'lead.validation_messages.typeResponse.limit_options'
    ),
  }));

  useEffect(() => {
    setError(validateOption(optionValue, responseOptions, validationMessages));
  }, [optionValue, responseOptions, validationMessages]);

  const hanldeAddNewOption = () => {
    const isOptionEmpty = isEmpty(optionValue);
    const isOptionDuplicated = responseOptions.some((option) =>
      isStringValueEqual(option, optionValue)
    );

    if (!isOptionEmpty && !isOptionDuplicated) {
      onAddNewOption(optionValue);
      setOptionValue('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      hanldeAddNewOption();
      setOptionValue('');
    }
  };

  const handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOptionValue(value);
  };

  const handleCleanOptionsInput = () => {
    setOptionValue('');
  };

  const fieldProps = {
    id: 'response-options',
    value: optionValue,
    type: 'text',
    placeholder: translate('lead.response_type_options.placeholder'),
    maxLength: MATCH_VALUE_CHAR_LIMIT,
    minLength: MATCH_VALUE_CHAR_MINIMUM,
    autoComplete: 'off',
    onChange: handleChangeOption,
    onKeyDown: handleKeyDown,
  };

  return (
    <StyledTypeResponse>
      <Text data-testid="response_type_label" fontWeight={'bold'}>
        {translate('lead.response_type_label')}
      </Text>
      <HStack my="2">
        <RadioGroup defaultValue={typeResponse}>
          <HStack>
            <Box p="2" rounded="lg" _hover={{ backgroundColor: '#e6e6e6' }}>
              <Radio
                type="radio"
                role="radio"
                id={`text-radio-${triggerId ?? ''}`}
                data-testId="type-response-texts"
                value="text"
                name="type-response-texts"
                isChecked={typeResponse === 'text'}
                onChange={() => {
                  onUpdateTypeResponse('text');
                  handleCleanOptionsInput();
                }}
              >
                {translate('lead.response_type_options.text')}
              </Radio>
            </Box>
            {companyType === 'lead' && (
              <Box p="2" rounded="lg" _hover={{ backgroundColor: '#e6e6e6' }}>
                <Radio
                  type="radio"
                  role="radio"
                  data-testid="type-response-buttons"
                  id={`button-radio-${triggerId ?? ''}`}
                  name="type-response-buttons"
                  value="button"
                  isChecked={typeResponse === 'button'}
                  onChange={() => {
                    onUpdateTypeResponse('button');
                  }}
                >
                  {translate('lead.response_type_options.buttons')}
                </Radio>
              </Box>
            )}
          </HStack>
        </RadioGroup>
      </HStack>
      {typeResponse === 'button' && (
        <div>
          <Text mb="2" fontWeight="bold" fontSize="14px">
            {translate('lead.response_type_options.options')}
          </Text>
          <HStack>
            <FormControl
              isDisabled={
                typeResponse !== 'button' ||
                (error.hasError && error.typeError === 'limit_options')
              }
              isInvalid={error.hasError && error.typeError === 'is_duplicated'}
              flex="1"
            >
              <HStack>
                <Input
                  data-testid="response_type_options.buttons"
                  {...fieldProps}
                  borderColor={'#B0B0B0;'}
                  focusBorderColor={'none'}
                  _hover={{ borderColor: '#B0B0B0;' }}
                />
                <Text
                  ml="4"
                  as={'a'}
                  cursor={'pointer'}
                  fontWeight={'400'}
                  color={
                    error.hasError && error.typeError === 'limit_options'
                      ? '#B0B0B0'
                      : '#1ABC9C'
                  }
                  whiteSpace="nowrap"
                  onClick={hanldeAddNewOption}
                >
                  <Text
                    data-testid="response_type_options_buttons_add"
                    as={'span'}
                    mr={'5px'}
                  >
                    <i className="icon icon-add" />{' '}
                  </Text>
                  {translate('add_another')}
                </Text>
              </HStack>
              {error.hasError && (
                <FormErrorMessage>{error.errorMessage}</FormErrorMessage>
              )}
              {error.hasError && error.typeError === 'limit_options' && (
                <FormHelperText>{error.errorMessage}</FormHelperText>
              )}
            </FormControl>
          </HStack>
          <div>
            <Wrap mt="2">
              {typeResponse === 'button' &&
                responseOptions.map((elem: string, i: number) => (
                  <WrapItem d="inline-block" mt="2" key={`${elem}-${i}`}>
                    <Tag
                      size="md"
                      borderRadius="full"
                      variant="outline"
                      colorScheme="blue"
                    >
                      <TagLabel>{elem}</TagLabel>
                      <TagCloseButton
                        data-testid={`${elem}-close-action`}
                        onClick={() => onRemoveOption(elem)}
                      />
                    </Tag>
                  </WrapItem>
                ))}
            </Wrap>
          </div>
        </div>
      )}
    </StyledTypeResponse>
  );
};

export default TypeResponse;
