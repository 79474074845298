import { ITrigger } from './interfaces';

export function parseTriggerResponse(
  trigger: Partial<ITrigger>
): Partial<ITrigger> {
  return {
    ...trigger,
    response: trigger?.response?.replaceAll('"', "'"),
  };
}
