/* eslint-disable prettier/prettier */
export default {
  title: 'Configuración avanzada',
  description_embeded:
    'Configura palabras claves que suelen escribir tus visitantes y <b>no hayas incluido como botones de la conversación</b>, para interpretarlas y darles una respuesta personalizada',
  description_no_embeded: 'Configura palabras claves que suelen escribir tus visitantes para interpretarlas y darles una respuesta personalizada',
  info: 'Si ya creaste un botón en la conversación, el chatbot responderá correctamente cuando tu visitante presione esa opción o la escriba. No es necesario que crees una respuesta personalizada igual. Aquí puedes crear respuestas a <b>palabras o frases que no sean parte de la conversación que hayas configurado.</b>',
  another_intervenes:
    'If another user intervenes in the conversation, prioritize the assignment by:',
  customer_write: 'If customer writes:',
  add_another: 'Add another one',
  add_another_ask: 'Add way to ask',
  bot_will_reply: 'The bot will reply:',
  response_actions: 'Response actions:',
  matching_type_label: 'Matching type:',
  matching_type_title: 'Matching types:',
  user_assignment: 'User assignment:',
  user_assignment_placeholder: 'Select user',
  select_user: 'Select user',
  tag: 'Tag',
  done: 'Done',
  discard: 'Descard',
  assignment_priority_options: {
    trigger: 'Custom responses',
    intervened: 'Cliengo Live',
  },
  end_conversation:
    'Stop conversation after response is triggered and ignore lead.',
  notificactions: {
    trigger_created_success: 'The quick reply has been created.',
    trigger_created_error: 'There was an error while saving.',
    trigger_updated_success: 'You have edited the quick reply.',
    trigger_updated_error: 'You have updated the quick reply.',
    trigger_removed_success: 'You have removed the quick reply.',
    trigger_removed_error: 'There was an error while removing.',
    trigger_invalid_fields_error: {
      title: 'Ups',
      desciption: 'You should enter at least une question and one answer',
    },
    assignment_priority_updated_success:
      'Assignment priority has been edited correctly.',
    assignment_priority_updated_error: 'There was an error while updating.',
  },
  request_confirm: {
    ask_delete_trigger: {
      title: 'Delete quick reply',
      description:
        'By deleting this quick reply, the different ways of asking will also be deleted',
      rejectLabel: 'Cancel',
      confirmLabel: 'Ok',
    },
  },
  delete_trigger: 'Delete',
  convex: {
    setup_keywords: 'Set up your keywords',
    dataTrigger: {
      new_keyword: 'New keyword',
      answer_keyword: 'Answer to keyword',
      how_to_ask: 'How to ask:',
    },
    editor_placeholder: 'Keyword response',
    editor_error_message: 'You must add an answer.',
    pause_conversaton:
      'Pause the conversation until the next message from the visitor.',
    keywords: 'Keywords',
    your_keywords: 'Your custom responses:',
    add_new_keyword: 'Add a new keyword',
    matching_type_options: {
      smarts: 'Smart',
      contains: 'Contains',
      equals: 'Accurate',
    },
    tooltips: {
      customer_write:
        'Add different ways of asking the same question. The robot will better understand what the associated answer is. We recommend adding at least 3 ways to ask.',
      matching_type:
        'Exact: The configured question must completely match the customer\'s query. \n\nContains: If what is required is that a question is contained in a single or multi-word query, then it is best to use this mode. \n\nSmart: This mode is used to perform text searches for similar words where the words do not have to be completely spelled out.',
      matching_type_array: [
        '<strong>Exact:</strong> The configured question must completely match the customer\'s query.',
        '<strong>Contains:</strong> If what is required is that a question is contained in a single or multi-word query, then it is best to use this mode.',
        '<strong>Smart:</strong> This mode is used to perform text searches for similar words where the words do not have to be completely spelled out.',
      ],
      setup_keywords:
        'Keywords help to answer your visitors" questions, even if they are not included in the conversational tree.',
    },
  },

  lead: {
    setup_responses: 'Set up custom responses',
    dataTrigger: {
      new_response: 'New response',
      answer_response: 'Custom response',
      how_to_ask: 'How to ask:',
    },
    editor_placeholder: 'Reply to custom response',
    editor_error_message: 'You must add an answer.',
    pause_conversaton:
      'When activating the radio buttons, the chatbot will wait\n for a response from the customer before continuing.',
    customized_responses: 'Custom responses',
    your_responses: 'Your custom responses:',
    add_new_response: 'Add new custom responses',
    matching_type_options: {
      smarts: 'Smart',
      contains: 'Contains',
      equals: 'Accurate',
      regex: 'Regex (Advance)',
    },
    response_type_label: 'Response Type',
    response_type_options: {
      text: 'Text',
      buttons: 'Button',
      options: 'Options (maximum 12)',
      placeholder: 'Add new option',
    },
    tooltips: {
      customer_write:
        'Add different ways of asking the same question. The robot will better understand what the associated answer is. We recommend adding at least 3 ways to ask.',
      matching_type:
        'Exact: The configured question must completely match the customer\'s query. \n\nContains: If what is required is that a question is contained in a single or multi-word query, then it is best to use this mode. \n\nSmart: This mode is used to perform text searches for similar words where the words do not have to be completely spelled out. \n\nRegex: This is used to program regular expressions. This option should only be used if you have programming skills.',
      matching_type_array: [
        '<strong>Exact:</strong> The configured question must completely match the customer\'s query.',
        '<strong>Contains:</strong> If what is required is that a question is contained in a single or multi-word query, then it is best to use this mode.',
        '<strong>Smart:</strong> This mode is used to perform text searches for similar words where the words do not have to be completely spelled out.',
        '<strong>Regex:</strong> This is used to program regular expressions. This option should only be used if you have programming skills.',
      ],
      setup_responses:
        'Create answers to specific questions from your business that visitors ask on your site.',
    },
    validation_messages: {
      matchs: {
        noEmpty: 'You must add an answer.',
        tooLong: 'Oops! Asking ways allow up to 20 characters',
        tooShort: 'The asking way must be at least 4 characters long.',
      },
      typeResponse: {
        noEmpty: 'Option must contain characters',
        isDuplicated:
          'The option text has been duplicated, try replacing the text.',
        limit_options: "You've reached the limit of allowed options.",
      },
    },
  },
  smart_triggers: {
    title_suggested_response: 'Add suggested responses AI',
    subtitle_suggested_response:
      'Analyzed by our artificial intelligence model based on your conversations with customers.',
    reply_to: 'Reply to:',
    add: 'Add',
    ignore: 'Ignore',
    error: 'An error has occurred, smart suggestions may not be available.',
  },
};
