import { isStringValueEqual } from './fieldValidators';

import { TYPE_BUTTON_LIMIT_OPTIONS } from '@/utils/constants';

const validateOption = (
  inputValue: string,
  options: string[],
  messages: { [key: string]: string }
) => {
  if (options) {
    if (options.some((option) => isStringValueEqual(option, inputValue))) {
      return {
        hasError: true,
        typeError: 'is_duplicated',
        errorMessage: messages.isDuplicated,
      };
    }

    if (options.length >= TYPE_BUTTON_LIMIT_OPTIONS) {
      return {
        hasError: true,
        typeError: 'limit_options',
        errorMessage: messages.limitOptions,
      };
    }
  }

  return { hasError: false, typeError: null, errorMessage: null };
};

export { validateOption };
