import React, { useState, useRef, useEffect } from 'react';
import {
  chakra,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Input,
  Box,
  Text,
  SlideFade,
} from '@chakra-ui/react';

import { Dropdown } from '../Dropdown/Dropdown.component';
import { MessageHelper } from '../MessageHelper/MessageHelper.component';

import {
  isAlphaNumeric,
  allOptions,
  filterData,
} from './utils/inputSelectUtils';
import { ISelectAssignConfig } from './inputSelect.types';

import { IUsersListData, IUsersItemListData } from '@/adapters/userDataAdapter';

const initialCurrentSelected = (
  options: ISelectAssignConfig['config']['options'],
  id: ISelectAssignConfig['config']['id']
) => {
  const currentOption = allOptions(options).find(
    (option: IUsersItemListData) => option.id === id
  );
  return {
    id: currentOption?.id ?? '',
    label: currentOption?.name ?? '',
    index: currentOption?.index,
  };
};

export const InputSelect = ({ config }: ISelectAssignConfig) => {
  const inputRef = useRef() as React.RefObject<HTMLInputElement>;
  const [editMode, setEditMode] = useState(false);

  const [selectOptions, setSelectOptions] = useState<IUsersListData>(
    config.options
  );

  const [currentSelectedOption, setCurrentSelectedOption] = useState<{
    id: string;
    label: string;
  }>({
    id: '',
    label: '',
  });

  const [selectedIndex, setSelectIndex] = useState(0);
  const [showError, setShowError] = useState(false);
  const { texts, onSelectAssigned, onRemoveAssigned } = config;

  useEffect(() => {
    setSelectOptions(filterData(config.options, '') as IUsersListData);
  }, [config]);

  useEffect(() => {
    setCurrentSelectedOption(
      initialCurrentSelected(selectOptions, config.value.id)
    );
  }, [selectOptions, config.value.id]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleRemoverSelection = () => {
    setCurrentSelectedOption({ id: '', label: '' });
    onRemoveAssigned(currentSelectedOption.id);
  };

  const handleOnChange = () => {
    setSelectIndex(0);
    setShowError(false);
    const newValue = inputRef.current?.value;

    if (newValue) {
      if (!isAlphaNumeric(newValue)) return;
    }
    const filteredData = filterData(config.options, newValue);

    setSelectOptions(filteredData as IUsersListData);

    setCurrentSelectedOption({
      ...currentSelectedOption,
      label: newValue ?? '',
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const flatOptions = allOptions(selectOptions);

    setCurrentSelectedOption({
      label: flatOptions[selectedIndex].name,
      id: flatOptions[selectedIndex].id,
    });

    toggleEditMode();

    onSelectAssigned(flatOptions[selectedIndex].id);
  };

  const handleDropdownSelect = (item: Partial<IUsersItemListData>) => {
    setCurrentSelectedOption({
      label: item.name ?? '',
      id: item.id ?? '',
    });
    toggleEditMode();
    onSelectAssigned(item.id ?? '');
  };

  const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const expectedKeys = ['40', '38'];
    if (expectedKeys.includes(e.key)) {
      if (!selectOptions) return;

      const flatOptions = allOptions(selectOptions);

      if (e.key === '40' && selectedIndex < flatOptions.length - 1) {
        setSelectIndex(selectedIndex + 1);
        document.querySelector(`#dropdown-${selectedIndex}`)?.scrollIntoView({
          behavior: 'smooth',
        });
      }
      if (e.key === '38' && selectedIndex > 0) {
        setSelectIndex(selectedIndex - 1);
        document.querySelector(`#dropdown-${selectedIndex}`)?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      {editMode && (
        <Box
          onClick={toggleEditMode}
          id="shadow-click"
          position="absolute"
          top="0"
          left="0"
          h="100%"
          w="100%"
        />
      )}
      <chakra.div mb="4" position="relative">
        <HStack
          as="div"
          onSubmit={handleSubmit}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="5px"
        >
          {!editMode ? (
            <Box p="2" height="100%" width="100%" onClick={toggleEditMode}>
              {currentSelectedOption.label ? (
                <SlideFade offsetX="-10px" offsetY="0" in={true}>
                  <Tag variant="solid" colorScheme="blue">
                    <TagLabel onClick={toggleEditMode}>
                      {currentSelectedOption.label}
                    </TagLabel>
                    <TagCloseButton onClick={handleRemoverSelection} />
                  </Tag>
                </SlideFade>
              ) : (
                <Text
                  fontSize={{
                    base: '14px',
                    lg: '16px',
                  }}
                  color="gray.500"
                >
                  {texts.placeholder}
                </Text>
              )}
            </Box>
          ) : (
            <>
              <Input
                p="2"
                ref={inputRef}
                placeholder={texts.placeholder}
                value={currentSelectedOption.label}
                onChange={handleOnChange}
                onKeyDown={handleKeyDownInput}
                variant="unstyled"
                autoComplete="off"
              />
            </>
          )}
          <Box px={2} onClick={toggleEditMode}>
            <chakra.i
              className={`icon icon-cheveron-${editMode ? 'up' : 'down'}`}
            />
          </Box>
        </HStack>
        {showError && (
          <MessageHelper color="error">{texts.messageIsError}</MessageHelper>
        )}
        {!editMode && texts?.helper && (
          <MessageHelper>{texts.helper}</MessageHelper>
        )}
        <Dropdown
          data-testid={config['data-testid'] as string}
          selectedIndex={selectedIndex}
          show={editMode && !showError}
          itemsDropdown={selectOptions}
          onSelect={(item: Partial<IUsersItemListData>) =>
            handleDropdownSelect(item)
          }
        />
      </chakra.div>
    </>
  );
};
