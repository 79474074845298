import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  setPendingStatus,
  setFailedStatus,
} from '../reducers/setActionStatusReducer';
import {
  websiteDataAdapter,
  IAppWebsiteData,
  IWebsiteData,
} from '../../adapters/websiteDataAdapter';

import updateAssignPriorityAction, {
  updateAssignPriorityReducer,
} from './actions/updateAssignPriority.action';
import { getWebsiteReducer } from './websiteReducers';

import updateWebsite from '@/api/updateWebsite';

export const getWebsiteAction = createAsyncThunk(
  'website/getWebsite' as string,
  async (siteId: string) => {
    const response: IAppWebsiteData = await websiteDataAdapter(siteId);
    return response;
  }
);

interface IUpdateWebsite {
  siteId: IAppWebsiteData['siteId'];
  updateWebsiteData: Partial<IAppWebsiteData>;
}

export const updateWebsiteAction = createAsyncThunk(
  'website/updateWebsite' as string,
  async ({ siteId, updateWebsiteData }: IUpdateWebsite) => {
    const response: IWebsiteData = await updateWebsite(
      siteId,
      updateWebsiteData
    );

    return response;
  }
);

const initialState: IAppWebsiteData = {
  siteId: '',
  editorType: 'website',
  lang: undefined,
  labs: {},
  assignPriority: ['TRIGGER', 'INTERVENED', 'ROUND_ROBIN'],
  autoAssign: [],
  tags: [],
  availableSmartTriggers: [],
  status: 'idle',
};

export const userSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWebsiteAction.pending, setPendingStatus);
    builder.addCase(getWebsiteAction.fulfilled, getWebsiteReducer);
    builder.addCase(getWebsiteAction.rejected, setFailedStatus);
    builder.addCase(updateAssignPriorityAction.pending, setPendingStatus);
    builder.addCase(
      updateAssignPriorityAction.fulfilled,
      updateAssignPriorityReducer
    );
    builder.addCase(updateAssignPriorityAction.rejected, setFailedStatus);
  },
});

export default userSlice.reducer;
