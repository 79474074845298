import { fetch } from '@/utils/fetch';
import {
  IDataResponse,
  IAppSmartSuggestion,
} from '@/store/smart-suggestions/smart-suggestions.types';
import { IAppWebsiteData } from '@/adapters/websiteDataAdapter';
import { URLS } from '@/utils/constants';

const discardSmartSuggestionData = async (
  siteId: IAppWebsiteData['siteId'],
  suggestion: IAppSmartSuggestion
) => {
  const config = {
    method: 'post',
    baseURL: URLS.AWS_API,
    url: `/collocation/smart_triggers/reject`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      website_id: siteId,
      level: 'WEBSITE',
      bigram: suggestion.term,
    },
  };
  try {
    (await fetch(config)) as unknown as IDataResponse;
    return suggestion.id;
  } catch (error) {
    throw new Error('Failed to discard smart suggestion');
  }
};

export default discardSmartSuggestionData;
