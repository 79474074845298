import { URLS } from '@/utils/constants';
import { fetch } from '@/utils/fetch';
import {
  IDataResponse,
  IDataSuggestions,
} from '@/store/smart-suggestions/smart-suggestions.types';

export default async function getSmartSuggestionsData(
  siteId: string
): Promise<IDataSuggestions> {
  const config = {
    method: 'get',
    baseURL: URLS.AWS_API,
    url: `collocation/smart_triggers?level=WEBSITE&website_id=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = (await fetch(config)) as unknown as IDataResponse;
    const { data } = response;
    return data;
  } catch (error) {
    throw new Error('Failed to fetch smart triggers data');
  }
}
