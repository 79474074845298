import { useState, useRef, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';

import { IFieldControlProps, IFieldErrorProps } from './InputControl.types';

const InputControl = ({ configProps }: IFieldControlProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState<string>(configProps.value);
  const [error, setError] = useState<IFieldErrorProps>({
    hasError: false,
    errorMessage: '',
  });

  useEffect(() => {
    setValue(configProps.value);
  }, [configProps]);

  const {
    id,
    disabled,
    texts,
    onChange: onChangeHandler,
    onBlur: onBlurHandler,
    onError,
    onKeyDown: onKeyDownHandler,
    maxLength,
    minLength,
    autoComplete,
  } = configProps;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setError(onError(e.target.value));
    if (onChangeHandler) {
      onChangeHandler(e.target.value);
    }
  };

  const handleOnBlur = () => {
    if (onBlurHandler && value) {
      onBlurHandler(value);
    }
  };

  const fieldProps = {
    id,
    value: value,
    type: 'text',
    disabled,
    placeholder: texts?.placeholder,
    minLength,
    maxLength,
    autoComplete: autoComplete,
    onBlur: handleOnBlur,
    onChange: handleOnChange,
    onKeyDown: onKeyDownHandler,
    ref: inputRef,
  };

  return (
    <FormControl isDisabled={disabled} isInvalid={error.hasError} flex="1">
      {texts?.label && (
        <FormLabel htmlFor={id} fontWeight="bold" fontSize="14px">
          {texts.label}
        </FormLabel>
      )}
      <Input
        data-testid={configProps['data-testid'] as string}
        {...fieldProps}
        fontSize={{
          base: '14px',
          lg: '16px',
        }}
        borderColor={'#B0B0B0;'}
        focusBorderColor={'none'}
        _hover={{ borderColor: '#B0B0B0;' }}
      />
      {!error.hasError && texts?.helper && (
        <FormHelperText>{texts.helper}</FormHelperText>
      )}
      {error.hasError && (
        <FormErrorMessage>{error.errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default InputControl;
