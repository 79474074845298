/*
  eslint-disable @typescript-eslint/no-unsafe-call,
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/interactive-supports-focus
*/
import {
  chakra,
  Box,
  Tooltip,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  UseDisclosureProps,
  Grid,
} from '@chakra-ui/react';

import { IAppChatbotConfigData } from '@/adapters/chatbotConfigAdapter';
import { translate } from '@/utils/i18n/translate';

interface InfoModalProps extends UseDisclosureProps {
  onClose: () => void;
  isOpen: boolean;
  companyType: IAppChatbotConfigData['companyType'];
}

const InfoModal = ({
  isOpen,
  onClose,
  companyType,
}: InfoModalProps): JSX.Element => {
  const translateForCompanyType = `${
    companyType === 'lead' ? 'lead' : 'convex'
  }.tooltips.matching_type_array`;

  return (
    <Modal isOpen={!!isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="6">
        <ModalHeader>{translate('matching_type_title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ul>
            {translate(translateForCompanyType).map((item: string) => (
              <chakra.li
                key={`matching_type-${item}`}
                dangerouslySetInnerHTML={{ __html: item }}
                mb="2"
              />
            ))}
          </ul>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface QuestionsHeadersProps {
  companyType: IAppChatbotConfigData['companyType'];
}

const QuestionsHeader = ({ companyType }: QuestionsHeadersProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Grid
      gap={4}
      templateColumns={{
        base: '1fr 1fr',
        lg: '1fr .95fr',
      }}
      marginBottom={'10px'}
    >
      <InfoModal isOpen={isOpen} onClose={onClose} companyType={companyType} />
      <Box display={'flex'} alignItems={'center'}>
        <Text
          data-testid="customer_write"
          fontSize={{
            base: '14px',
            lg: '16px',
          }}
          fontWeight={'bold'}
        >
          {translate('customer_write')}
        </Text>
        <Tooltip
          label={`${translate('convex.tooltips.customer_write')}:`}
          aria-label="tooltip-questionsHeading"
        >
          <Box
            display={{
              base: 'none',
              lg: 'flex',
            }}
            marginLeft={'8px'}
            alignItems={'center'}
          >
            <i className="icon-info" />
          </Box>
        </Tooltip>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Text
          data-testid="matching_type_label"
          fontSize={{
            base: '14px',
            lg: '16px',
          }}
          fontWeight={'bold'}
          ml={'10px'}
        >
          {translate('matching_type_label')}
        </Text>
        <Box
          display={{
            base: 'none',
            lg: 'flex',
          }}
          marginLeft={'8px'}
          alignItems={'center'}
        >
          <i className="icon-info" role="button" onClick={onOpen} />
        </Box>
      </Box>
    </Grid>
  );
};

export default QuestionsHeader;
