import { useEffect } from 'react';

import { useAppDispatch } from '@/hooks/useRedux/redux';
import { getUser, getUsersList } from '@/store/user/user.slice';
import { layoutActions } from '@/store/layout/layout.slice';
import { getAccountAction } from '@/store/account/account.slice';
import { getWebsiteAction } from '@/store/website/website.slice';
import { getChatbotAction } from '@/store/chatbot/chatbot.slice';
import getTriggersAction from '@/store/trigger/actions/getTriggers.action';

export const useStartAppData = (siteId: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUser())
      .unwrap()
      .then(() => {
        dispatch(layoutActions.setLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(layoutActions.setError(true));
      });
    dispatch(getAccountAction())
      .unwrap()
      .then(() => {
        dispatch(layoutActions.setLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(layoutActions.setError(true));
      });
    dispatch(getWebsiteAction(siteId))
      .unwrap()
      .then(() => {
        dispatch(layoutActions.setLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(layoutActions.setError(true));
      });
    dispatch(getUsersList())
      .unwrap()
      .then(() => {
        dispatch(layoutActions.setLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(layoutActions.setError(true));
      });
    dispatch(getTriggersAction(siteId))
      .unwrap()
      .then(() => {
        dispatch(layoutActions.setLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(layoutActions.setError(true));
      });
    dispatch(getChatbotAction())
      .unwrap()
      .then(() => {
        dispatch(layoutActions.setLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(layoutActions.setError(true));
      });
  }, [siteId, dispatch]);
};
