import { Text, Box, chakra, Flex } from '@chakra-ui/react';

import { AddNewTriggerProps } from './AddNewTrigger.types';

const AddNewTrigger = ({ label, onShowForm }: AddNewTriggerProps) => (
  <Box
    mx={{
      base: 4,
      lg: 8,
    }}
    d="flex"
    flexDirection={'column'}
  >
    <Flex
      justifyContent="space-between"
      bg="white"
      p="6"
      rounded="lg"
      alignItems="center"
    >
      <Text
        data-testid="text_create_trigger"
        w={{
          base: '80%',
          lg: '100%',
        }}
        fontWeight="bold"
      >
        {label}
      </Text>
      <chakra.button
        display="flex"
        alignItems="center"
        justifyContent="center"
        alignSelf="flex-end"
        bg="primary"
        h={{
          base: '40px',
          lg: '24px',
        }}
        w={{
          base: '40px',
          lg: '24px',
        }}
        rounded="md"
        onClick={onShowForm}
        data-testid="add-new-trigger"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="#ff000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
        </svg>
      </chakra.button>
    </Flex>
  </Box>
);

export default AddNewTrigger;
