export const setExpDate = (expTime: {
  number: number;
  unit: 'days' | 'hours' | 'minutes' | 'seconds';
}) => {
  const { number, unit } = expTime;
  const expDate = new Date();

  switch (unit) {
    case 'days':
      expDate.setDate(expDate.getDate() + number);
      break;
    case 'hours':
      expDate.setHours(expDate.getHours() + number);
      break;
    case 'minutes':
      expDate.setMinutes(expDate.getMinutes() + number);
      break;
    case 'seconds':
      expDate.setSeconds(expDate.getSeconds() + number);
      break;
    default:
      break;
  }

  return expDate;
};

export const isDateExpired = (date: string) => {
  const today = new Date();

  return new Date(date) < today;
};
