import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  IAppSmartSuggestion,
  IAppSmartSuggestions,
} from './smart-suggestions.types';
import getSmartSuggestionsAction from './actions/get-smart-suggestions.action';
import rejectSmartSuggestionAction from './actions/reject-smart-suggestion.action';

import { store } from '@/store';

const smartSuggestionsAdapter = createEntityAdapter<IAppSmartSuggestion>({
  selectId: (smartSuggestion: IAppSmartSuggestion) => smartSuggestion.id,
});

export const smartSuggestionsSlice = createSlice({
  name: 'smart-suggestions',
  initialState: smartSuggestionsAdapter.getInitialState({
    selectedSuggestionId: '',
    isLoading: false,
    hasError: false,
    status: 'idle',
  }),
  reducers: {
    getSuggestionSelected: (
      state,
      { payload }: PayloadAction<IAppSmartSuggestion['id']>
    ) => {
      state.selectedSuggestionId = payload;
    },
    removeSuggestionSelected: (state) => {
      state.selectedSuggestionId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmartSuggestionsAction.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(
      getSmartSuggestionsAction.fulfilled,
      (state, { payload }: PayloadAction<IAppSmartSuggestions>) => {
        state.isLoading = false;
        state.status = 'succeeded';
        smartSuggestionsAdapter.setAll(state, payload);
      }
    );
    builder.addCase(getSmartSuggestionsAction.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
      state.status = 'failed';
    });
    builder.addCase(rejectSmartSuggestionAction.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(
      rejectSmartSuggestionAction.fulfilled,
      (state, { payload }: PayloadAction<IAppSmartSuggestion['id']>) => {
        state.isLoading = false;
        state.status = 'succeeded';
        smartSuggestionsAdapter.removeOne(state, payload);
      }
    );
    builder.addCase(rejectSmartSuggestionAction.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
      state.status = 'failed';
    });
  },
});

type RootState = ReturnType<typeof store.getState>;

export const smartSuggestionsSelectors =
  smartSuggestionsAdapter.getSelectors<RootState>(
    (state) => state.smartSuggestions
  );

export const smartSuggestionsActions = smartSuggestionsSlice.actions;

export default smartSuggestionsSlice.reducer;
