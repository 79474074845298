import { PayloadAction } from '@reduxjs/toolkit';

import { IAppAccountData } from '@/adapters/accounDataAdapter';

export const getAccountReducer = (
  state: IAppAccountData,
  { payload }: PayloadAction<IAppAccountData>
) => ({
  ...state,
  ...payload,
  status: 'succeeded',
});
