import { configureStore } from '@reduxjs/toolkit';

import layoutReducer from './layout/layout.slice';
import userReducer from './user/user.slice';
import accountReducer from './account/account.slice';
import websiteReducer from './website/website.slice';
import chatbotReducer from './chatbot/chatbot.slice';
import triggerReducer from './trigger/trigger.slice';
import smartSuggestionsReducer from './smart-suggestions/smart-suggestions.slice';

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    user: userReducer,
    account: accountReducer,
    website: websiteReducer,
    chatbot: chatbotReducer,
    trigger: triggerReducer,
    smartSuggestions: smartSuggestionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
