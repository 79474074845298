import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import {
  ITriggersList,
  ITrigger,
  IAppTrigger,
} from '../../../utils/interfaces';
import { setSucceededStatus } from '../../reducers/setActionStatusReducer';

import postTrigger from '@/api/postTrigger';
import { singleDtoTrigger } from '@/utils/mappers/triggerDtoApp.mapper';

interface ICreateTriggerData {
  siteId: string;
  dataTrigger: Partial<ITrigger>;
}

const createTriggerAction = createAsyncThunk(
  'trigger/createTrigger',
  async ({ siteId, dataTrigger }: ICreateTriggerData) => {
    const response: ITrigger = await postTrigger(siteId, dataTrigger);

    return singleDtoTrigger(response);
  }
);

export const createTriggerReducer = (
  state: ITriggersList,
  { payload }: PayloadAction<IAppTrigger>
) => {
  state.results.push(payload);
  setSucceededStatus(state);
};

export default createTriggerAction;
