import { ITrigger } from '../interfaces';

const typeResponse = (options: ITrigger['responseOptions']) => {
  if (!options.length) {
    return 'text';
  }
  return 'button';
};

export default typeResponse;
