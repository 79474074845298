import { IAppWebsiteData } from '@/adapters/websiteDataAdapter';

const hasSmartTriggers = (
  labs: IAppWebsiteData['labs'],
  availableSmartTriggers: IAppWebsiteData['availableSmartTriggers']
) => {
  if (labs.cvanalysis && availableSmartTriggers.includes('WEBSITE')) {
    return true;
  }
  return false;
};

export default hasSmartTriggers;
