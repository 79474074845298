import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import { FeedbackModalProps } from './feedbackModal.types';

const FeedbackModal = ({ contentProps, controlProps }: FeedbackModalProps) => {
  const { isOpen, onClose } = useDisclosure(controlProps);

  const { title, description, rejectLabel, confirmLabel } = contentProps;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          <Button variant="outlinePrimary" mr={3} onClick={onClose}>
            {rejectLabel}
          </Button>
          <Button variant="danger" onClick={controlProps.onConfirm}>
            {confirmLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
