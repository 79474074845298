import { Box, Text } from '@chakra-ui/react';

import { MessageHelperProps } from './MessageHelper.types';

export const MessageHelper = ({ color, children }: MessageHelperProps) => (
  <Box mt={1}>
    <Text color={color || 'textMuted'}>{children}</Text>
  </Box>
);

export default MessageHelper;
