import { ITriggersList } from '../../utils/interfaces';

export const setPendingStatus = (state: ITriggersList): void => {
  state.status = 'pending';
};

export const setSucceededStatus = (state: ITriggersList): void => {
  state.status = 'succeeded';
};

export const setFailedStatus = (state: ITriggersList): void => {
  state.status = 'failed';
};
