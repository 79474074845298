import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITriggersList } from '../../utils/interfaces';
import {
  setPendingStatus,
  setFailedStatus,
} from '../reducers/setActionStatusReducer';

import getTriggersAction, {
  getTriggersReducer,
} from './actions/getTriggers.action';
import createTriggerAction, {
  createTriggerReducer,
} from './actions/createTrigger.action';
import deleteTriggerAction, {
  deleteTriggerReducer,
} from './actions/deleteTrigger.action';
import updateTriggerAction, {
  updateTriggerReducer,
} from './actions/updateTrigger.action';

const initialState: ITriggersList = {
  paging: {
    total: 0,
  },
  results: [],
  currentTrigger: null,
  status: 'idle',
};
export const triggerSlice = createSlice({
  name: 'trigger',
  initialState,
  reducers: {
    setCurrentTrigger: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (index >= 0) {
        state.currentTrigger = index === state.currentTrigger ? null : index;
      }
    },
    setCurrentTriggerNull: (state) => {
      state.currentTrigger = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTriggersAction.pending, setPendingStatus);
    builder.addCase(getTriggersAction.fulfilled, getTriggersReducer);
    builder.addCase(getTriggersAction.rejected, setFailedStatus);
    builder.addCase(createTriggerAction.pending, setPendingStatus);
    builder.addCase(createTriggerAction.fulfilled, createTriggerReducer);
    builder.addCase(updateTriggerAction.pending, setPendingStatus);
    builder.addCase(updateTriggerAction.fulfilled, updateTriggerReducer);
    builder.addCase(updateTriggerAction.rejected, setFailedStatus);
    builder.addCase(deleteTriggerAction.pending, setPendingStatus);
    builder.addCase(deleteTriggerAction.fulfilled, deleteTriggerReducer);
    builder.addCase(deleteTriggerAction.rejected, setFailedStatus);
  },
});

export const triggerActions = triggerSlice.actions;

export default triggerSlice.reducer;
