import { IUsersListData, IUsersItemListData } from '@/adapters/userDataAdapter';

function isAlphaNumeric(string: string) {
  return /^[a-zA-Z0-9áéíóú\s]+$/.test(string);
}

function allOptions(options: object) {
  const mergeredOptions = Object.keys(options).reduce(
    (acc: Array<IUsersItemListData>, key: string) => {
      return [...acc, ...options[key as keyof typeof options]];
    },
    []
  );
  return mergeredOptions;
}

const filterData = (items: IUsersListData, value = '') => {
  let newData = { ...items };
  // eslint-disable-next-line
  let isEmpty = 0;
  const regex = new RegExp(`${value}`, 'i');
  let index = -1;

  Object.keys(items).forEach((category) => {
    let itemsList;

    if (value === '') {
      itemsList = items[category as keyof typeof items];
    } else {
      itemsList = items[category as keyof IUsersListData].filter(
        (item: IUsersItemListData) => regex.test(item.name)
      );
    }
    // eslint-disable-next-line
    isEmpty += itemsList.length;

    newData = {
      ...newData,
      [category]: itemsList.map((item: IUsersItemListData) => {
        index += 1;
        return {
          ...item,
          index,
        };
      }),
    };
  });

  return newData;
};

export { isAlphaNumeric, allOptions, filterData };
