const isEditorEmpty = (editorState: string) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(editorState, 'text/html');
  const { innerText } = html.body;
  if (typeof innerText === 'string') {
    return !innerText.trim().length;
  }
  return false;
};

export { isEditorEmpty };
