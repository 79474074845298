import { URLS } from '@/utils/constants';
import { fetch } from '@/utils/fetch';

interface IQuestionListData {
  name: string;
  id: string;
  template_code: string;
  defaultMsgs: { [key: string]: string };
  language: 'es' | 'en' | 'pt';
  websiteId: string;
}

interface DataResponse {
  data: { results: IQuestionListData | IQuestionListData[] };
}

export interface IAppChatbotConfigData {
  companyType: 'lead' | 'convex' | 'ecommerce';
  chatbotName: string;
  questionList: { [key: string]: string };
  language: 'es' | 'en' | 'pt';
  chatbotConfigId: string;
  templateCode:
    | 'advanceConversation'
    | 'ecommerce'
    | 'tiendaNube'
    | 'undefined';
  status?: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export const chatbotConfigDataAdapter =
  async (): Promise<IAppChatbotConfigData> => {
    try {
      const { data } = (await fetch({
        baseURL: URLS.API_URL,
        url: '/sites/chatbots',
      })) as unknown as DataResponse;
      const chatbotData = Array.isArray(data.results)
        ? data.results[0]
        : data.results;
      const { name, template_code, defaultMsgs, language, websiteId } =
        chatbotData;
      return {
        chatbotName: name,
        chatbotConfigId: websiteId,
        templateCode: template_code as IAppChatbotConfigData['templateCode'],
        questionList: defaultMsgs,
        language,
        companyType: getCompanyType(chatbotData),
      };
    } catch (error) {
      throw new Error('Failed to fetch account data');
    }
  };

function getCompanyType(data: IQuestionListData) {
  const { template_code } = data;
  if (template_code === 'advancedConversation') {
    return 'convex';
  }
  if (['ecommerce', 'tiendaNube'].includes(template_code)) {
    return 'ecommerce';
  }
  return 'lead';
}
