import { useEffect, useState } from 'react';
import {
  chakra,
  Box,
  Container,
  Heading,
  Text,
  useToast,
  UseToastOptions,
  Tooltip,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Analytics } from 'cliengo-fe-utils';

import { TriggersParams } from './triggers.types';

import { useAppSelector, useAppDispatch } from '@/hooks/useRedux/redux';
import {
  TypesEvent,
  TypesFeedbackContent,
} from '@/utils/feedback/feedback.types';
import CreateTrigger from '@/components/CreateTrigger';
import Trigger from '@/components/Trigger';
import AssignmentPriority from '@/components/AssignmentPriority';
import { ASSIGNMENT_PRIORITY_OPTIONS } from '@/utils/constants';
import { IAppTrigger } from '@/utils/interfaces';
import updateTriggerAction from '@/store/trigger/actions/updateTrigger.action';
import deleteTriggerAction from '@/store/trigger/actions/deleteTrigger.action';
import updateAssignPriorityAction from '@/store/website/actions/updateAssignPriority.action';
import { IOptionAssignament } from '@/components/AssignmentPriority/assignmentPriority.component';
import FeedbackModal from '@/components/FeedbackModal';
import feedbackContents from '@/utils/feedback/feedbackContents';
import { initTranslation, translate } from '@/utils/i18n/translate';
import { triggerActions } from '@/store/trigger/trigger.slice';
import {
  smartSuggestionsSelectors,
  smartSuggestionsActions,
} from '@/store/smart-suggestions/smart-suggestions.slice';
import { IAppSmartSuggestion } from '@/store/smart-suggestions/smart-suggestions.types';
import SmartSuggestion from '@/components/SmartSuggestion';
import getSmartSuggestionsAction from '@/store/smart-suggestions/actions/get-smart-suggestions.action';
import rejectSmartSuggestionAction from '@/store/smart-suggestions/actions/reject-smart-suggestion.action';
import hasSmartTriggers from '@/utils/helpers/hasSmartTriggers';

const TriggersView = () => {
  const { siteId } = useParams<TriggersParams>();
  const triggers = useAppSelector((state) => state.trigger);
  const { name, planName, id, labs } = useAppSelector((state) => state.account);
  const { assignPriority, editorType, availableSmartTriggers } = useAppSelector(
    (state) => state.website
  );
  const { usersList, user } = useAppSelector((state) => state.user);
  const { companyType } = useAppSelector((state) => state.chatbot);
  const { isLoading, hasError: hasErrorSmartSuggestions } = useAppSelector(
    (state) => state.smartSuggestions
  );
  const allSuggestions = useAppSelector(smartSuggestionsSelectors.selectAll);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalToRemove, setModalToRemove] = useState<string>('');
  const [searchParams] = useSearchParams();

  const isEmbedded = searchParams.toString().includes('cocos');

  const dispatch = useAppDispatch();

  const toast = useToast();

  const hasSmartTriggersFlag = hasSmartTriggers(labs, availableSmartTriggers);

  useEffect(() => {
    Analytics.initialize({
      segmentWriteKey: '7WHI36JKuhdMtRxpJ0XmEivgGQzIztLN',
      sendAnalytics: 'YES',
      account: { name, planName, id },
      user: user,
    });
  }, [name, planName, id, user]);

  useEffect(() => {
    initTranslation(user.language);
  }, [siteId, user.language]);

  useEffect(() => {
    if (hasSmartTriggers(labs, availableSmartTriggers)) {
      dispatch(getSmartSuggestionsAction(siteId ?? ''))
        .unwrap()
        .catch((error) => {
          console.error(error);
        });
    }
  }, [labs, availableSmartTriggers, siteId, dispatch]);

  const handleDoneTrigger = (trigger: IAppTrigger): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hasUnsaveChanges, typeResponse, isSmartTrigger, ...restOfTrigger } =
      trigger;
    dispatch(
      updateTriggerAction({
        siteId: siteId ?? '',
        dataTrigger: restOfTrigger,
      })
    )
      .unwrap()
      .then(() => {
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationInfo
          ] as TypesFeedbackContent),
          title: translate('notificactions.trigger_updated_success'),
        });
        dispatch(triggerActions.setCurrentTrigger(triggers.results.length));
      })
      .catch((error) => {
        console.error(error);
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationError
          ] as TypesFeedbackContent),
          title: translate('notificactions.trigger_updated_error'),
        });
      });
  };

  const handleRemoveTrigger = (triggerId: string): void => {
    dispatch(deleteTriggerAction({ siteId: siteId ?? '', triggerId }))
      .unwrap()
      .then(() => {
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationInfo
          ] as TypesFeedbackContent),
          title: translate('notificactions.trigger_removed_success'),
        });
      })
      .catch((error) => {
        console.error(error);
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationError
          ] as TypesFeedbackContent),
          title: translate('notificactions.trigger_removed_error'),
        });
      });
  };

  const handleAssignmentPriority = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    dispatch(
      updateAssignPriorityAction({
        siteId: siteId ?? '',
        dataPriority: {
          assign_priority: [
            e.target.value,
            e.target.value === 'TRIGGER' ? 'INTERVENED' : 'TRIGGER',
            'ROUND_ROBIN',
            'QUESTION',
          ],
        },
      })
    )
      .unwrap()
      .then(() => {
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationInfo
          ] as TypesFeedbackContent),
          title: translate(
            'notificactions.assignment_priority_updated_success'
          ),
        });
      })
      .catch((error) => {
        console.error(error);
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationError
          ] as TypesFeedbackContent),
          title: translate('notificactions.assignment_priority_updated_error'),
        });
      });
  };

  const handleToggleVisivility = (triggerIndex: number): void => {
    dispatch(triggerActions.setCurrentTrigger(triggerIndex));
  };

  const handleResetVisivility = (): void => {
    dispatch(triggerActions.setCurrentTriggerNull());
  };

  const handleAddSmartTrigger = (suggestionId: IAppSmartSuggestion['id']) => {
    dispatch(smartSuggestionsActions.getSuggestionSelected(suggestionId));
  };

  const handleRejectSmartSuggestion = (suggestion: IAppSmartSuggestion) => {
    dispatch(rejectSmartSuggestionAction({ siteId: siteId ?? '', suggestion }))
      .unwrap()
      .then()
      .catch((error) => {
        console.error(error);
      });
  };

  const handleNotificationEvent = (feedback: TypesFeedbackContent): void => {
    toast(feedback as UseToastOptions);
  };

  return (
    <>
      {showModal && (
        <FeedbackModal
          contentProps={{
            title: translate('request_confirm.ask_delete_trigger.title'),
            description: translate(
              'request_confirm.ask_delete_trigger.description'
            ),
            rejectLabel: translate(
              'request_confirm.ask_delete_trigger.rejectLabel'
            ),
            confirmLabel: translate(
              'request_confirm.ask_delete_trigger.confirmLabel'
            ),
          }}
          controlProps={{
            id: 'deleteTrigger',
            isOpen: showModal,
            onClose: () => {
              setShowModal(false);
            },
            onConfirm: () => {
              handleRemoveTrigger(modalToRemove);
              setShowModal(false);
            },
          }}
        />
      )}
      <Box
        display={hasSmartTriggersFlag ? 'grid' : 'block'}
        gridTemplateColumns={{ base: '100%', lg: '1fr 380px' }}
        background={'#F3F3F3'}
      >
        <Box height={'100vh'} overflowY={'auto'} pr={'3rem'}>
          <Box
            id="title-section"
            px={12}
            py={{
              base: 4,
              lg: 6,
            }}
            mb={3}
          >
            <chakra.h4 fontWeight="bold">{translate('title')}</chakra.h4>
            <chakra.p
              dangerouslySetInnerHTML={{
                __html: translate(
                  isEmbedded ? 'description_embeded' : 'description_no_embeded'
                ),
              }}
            />
          </Box>
          <Box id="left-container" maxW="1024px">
            <Box
              id="create-trigger-section"
              backgroundColor="#e5e5e5"
              rounded="lg"
              pt={{
                base: 4,
                lg: 12,
              }}
              ml={10}
            >
              {!isEmbedded && (
                <Heading
                  data-testid="heading_triggers"
                  display="flex"
                  alignItems={'center'}
                  px={{
                    base: 4,
                    lg: 12,
                  }}
                  size="md"
                  mb="4"
                >
                  {companyType === 'lead'
                    ? translate('lead.setup_responses')
                    : translate('convex.setup_keywords')}
                  <Tooltip
                    label={
                      companyType === 'lead'
                        ? translate('lead.tooltips.setup_responses')
                        : translate('convex.tooltips.setup_keywords')
                    }
                    aria-label="tooltip-questionsHeading"
                  >
                    <Box marginLeft={'7px'} mt="4px">
                      <i className="icon-info" />
                    </Box>
                  </Tooltip>
                </Heading>
              )}
              <Box mx={4}>
                <CreateTrigger
                  label={
                    companyType === 'lead'
                      ? translate('lead.add_new_response')
                      : translate('convex.add_new_keyword')
                  }
                />
              </Box>
              <AssignmentPriority
                label={translate('another_intervenes')}
                options={ASSIGNMENT_PRIORITY_OPTIONS}
                currentSelection={
                  ASSIGNMENT_PRIORITY_OPTIONS.find(
                    (elem: IOptionAssignament) =>
                      elem.value === assignPriority[0]
                  ) as IOptionAssignament
                }
                onAssign={handleAssignmentPriority}
              />
            </Box>
            {/* SECTION TRIGGERS CARDS */}
            <Box id="triggers-list-section" mt={10} ml={10}>
              <Heading mb="2" size="md" data-testid="your_responses">
                {companyType === 'lead'
                  ? translate('lead.your_responses')
                  : translate('convex.your_keywords')}
              </Heading>
              <Box pb={12}>
                {triggers.results &&
                  [...triggers.results]
                    .reverse()
                    .map((trigger: IAppTrigger, i: number) => (
                      <Trigger
                        index={i}
                        key={`key-trigger-${i}-${trigger.id ?? ''}`}
                        show={triggers.currentTrigger === i}
                        trigger={trigger}
                        usersList={usersList}
                        editorType={editorType}
                        companyType={companyType}
                        hasControls={true}
                        accountLabs={labs}
                        onToggleVisivility={(index) =>
                          handleToggleVisivility(index as number)
                        }
                        onResetVisivility={handleResetVisivility}
                        onDone={handleDoneTrigger}
                        onDelete={() => {
                          setShowModal(true);
                          setModalToRemove(trigger.id ?? '');
                        }}
                      />
                    ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box id="smart-triggers-sidebar">
          {/* SMART TRIGGERS SECTION */}
          {hasSmartTriggersFlag && (
            <Container
              background={'#F9F9F9'}
              h={hasSmartTriggersFlag ? '100vh' : 'auto'}
              right={0}
              w="380px"
              top="0"
              pt={12}
              shadow="md"
              overflowY="auto"
              px="4"
              pb="4"
            >
              <i
                style={{
                  fontSize: '30px',
                  textAlign: 'center',
                  display: 'block',
                  marginBottom: '12px',
                }}
                className="icon icon-chatbot-happy"
              ></i>
              <Heading mb="2" size="md" textAlign="center">
                {translate('smart_triggers.title_suggested_response')}
              </Heading>
              <Text
                textAlign={'center'}
                fontSize="sm"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'smart_triggers.subtitle_suggested_response'
                  ),
                }}
              />
              <Box mr={2} ml={2} mt={5} mb={5} className="sidebar-card">
                {isLoading && !hasErrorSmartSuggestions ? (
                  <HStack justifyContent={'center'} alignItems={'center'}>
                    <Spinner
                      color="secondary.default"
                      size="xl"
                      speed="0.85s"
                    />
                  </HStack>
                ) : (
                  allSuggestions.map((suggestion: IAppSmartSuggestion) => {
                    return (
                      <SmartSuggestion
                        key={suggestion.id}
                        {...suggestion}
                        onAddSuggestion={(
                          suggestionId: IAppSmartSuggestion['id']
                        ) => {
                          handleAddSmartTrigger(suggestionId);
                        }}
                        onIgnoreSuggestion={(
                          suggestion: IAppSmartSuggestion
                        ) => {
                          handleRejectSmartSuggestion(suggestion);
                        }}
                      />
                    );
                  })
                )}
                {hasErrorSmartSuggestions && (
                  <Text textAlign={'center'} fontSize="sm">
                    {translate('smart_triggers.error')}
                  </Text>
                )}
              </Box>
            </Container>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TriggersView;
