interface IFeedback {
  title: string;
  description?: string;
}

export interface INotification extends IFeedback {
  status: string;
  duration: number;
  isClosable: boolean;
}

export interface IRequestConfirm extends IFeedback {
  rejectLabel: string;
  confirmLabel: string;
}

export type TypesFeedbackContent = INotification | IRequestConfirm;

export interface IFeedbackContents {
  [key: string]: TypesFeedbackContent;
}

export enum TypesEvent {
  notificationSuccess = 'notification_success',
  notificationInfo = 'notification_info',
  notificationError = 'notification_error',
  askDeleteTrigger = 'ask_delete_trigger',
}

export type Event =
  | TypesEvent.notificationSuccess
  | TypesEvent.notificationInfo
  | TypesEvent.notificationError
  | TypesEvent.askDeleteTrigger;
