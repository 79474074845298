import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { ITriggersList } from '../../../utils/interfaces';

import {
  IAppTriggersData,
  triggerDataAdapter,
} from '@/adapters/triggerDataAdapter';

const getTriggersAction = createAsyncThunk(
  'trigger/getTriggersAction',
  async (siteId: string) => {
    const response: IAppTriggersData = await triggerDataAdapter(siteId);
    return response;
  }
);

export const getTriggersReducer = (
  state: ITriggersList,
  { payload }: PayloadAction<ITriggersList>
) => ({
  ...state,
  ...payload,
  status: 'succeeded',
});

export default getTriggersAction;
