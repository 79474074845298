import { chakra, SlideFade } from '@chakra-ui/react';

import UserListMock from '../../mocks/userList.mock.json';

export type ItemCategory = typeof UserListMock;

import { DropdownProps, IDropdownItem } from './Dropdown.types';

export const Dropdown = ({
  itemsDropdown,
  show,
  onSelect,
  selectedIndex,
}: DropdownProps) => {
  const itemsDropdownKeys = Object.keys(
    itemsDropdown || {}
  ) as unknown as (keyof typeof itemsDropdown)[];

  const handleSelectedOption = (item: IDropdownItem) => {
    // eslint-disable-next-line
    const { index, ...itemWithoutIndex } = item;
    onSelect(itemWithoutIndex);
  };

  return (
    <>
      {show && (
        <SlideFade in={show} offsetY="20px" style={{ zIndex: 10 }}>
          <chakra.div
            bg="white"
            w="100%"
            boxShadow="md"
            maxHeight="250"
            position="absolute"
            zIndex="10"
            overflow="auto"
          >
            {itemsDropdownKeys.length === 0 ? (
              <chakra.p textAlign="center" py="6" color="gray.400">
                No hay elementos
              </chakra.p>
            ) : (
              itemsDropdownKeys.map(
                (category: keyof typeof itemsDropdown, index: number) => (
                  <chakra.div key={index}>
                    {itemsDropdown[category].length > 0 && (
                      <chakra.h6
                        mt="4"
                        pl="2"
                        color="gray.400"
                        borderBottom="1px"
                        borderColor="gray.300"
                        textTransform="uppercase"
                        fontWeight="semibold"
                      >
                        {category}
                      </chakra.h6>
                    )}
                    {itemsDropdown[category].map((item: IDropdownItem) => (
                      <chakra.p
                        key={item.id}
                        onClick={() =>
                          handleSelectedOption(
                            item as {
                              index: number;
                              id: string;
                              name: string;
                              'data-testid': string;
                            }
                          )
                        }
                        py="2"
                        pl="4"
                        id={`dropdown-${item.index}`}
                        data-testid={`dropdown-item-${item.index}`}
                        borderBottom="1px"
                        borderColor="gray.300"
                        bg={
                          selectedIndex === item.index
                            ? 'gray.100'
                            : 'transparent'
                        }
                        _hover={{ background: 'gray.200' }}
                      >
                        {item.name}
                      </chakra.p>
                    ))}
                  </chakra.div>
                )
              )
            )}
          </chakra.div>
        </SlideFade>
      )}
    </>
  );
};
