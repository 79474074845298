import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  setPendingStatus,
  setFailedStatus,
} from '../reducers/setActionStatusReducer';
import {
  accountDataAdapter,
  IAppAccountData,
  IAccountData,
} from '../../adapters/accounDataAdapter';

import { getAccountReducer } from './accountReducers';

export const getAccountAction = createAsyncThunk(
  'account/getAccount',
  async () => {
    const response: IAccountData = await accountDataAdapter();
    return response;
  }
);

const initialState: IAppAccountData = {
  id: '',
  name: '',
  lang: undefined,
  planName: '',
  labs: {},
  status: 'idle',
};

export const userSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountAction.pending, setPendingStatus);
    builder.addCase(getAccountAction.fulfilled, getAccountReducer);
    builder.addCase(getAccountAction.rejected, setFailedStatus);
  },
});

export default userSlice.reducer;
