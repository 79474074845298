import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  chatbotConfigDataAdapter,
  IAppChatbotConfigData,
} from '../../adapters/chatbotConfigAdapter';

import { getChatbotReducer } from './chatbotReducers';

export const getChatbotAction = createAsyncThunk(
  'chatbot/getChatbot',
  async () => {
    const response: IAppChatbotConfigData = await chatbotConfigDataAdapter();
    return response;
  }
);

const initialState: IAppChatbotConfigData = {
  companyType: 'lead',
  chatbotName: '',
  questionList: {},
  language: 'es',
  chatbotConfigId: '',
  templateCode: 'undefined',
};

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getChatbotAction.pending,
      (state: IAppChatbotConfigData) => {
        state.status = 'pending';
      }
    );
    // TODO: error
    builder.addCase(getChatbotAction.fulfilled, getChatbotReducer);
    builder.addCase(
      getChatbotAction.rejected,
      (state: IAppChatbotConfigData) => {
        state.status = 'failed';
      }
    );
  },
});

export default chatbotSlice.reducer;
