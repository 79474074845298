import { URLS } from '@/utils/constants';
import { fetch } from '@/utils/fetch';

export interface IAccountData {
  id: string;
  lang?: string;
  language?: string;
  planName: string;
  name: string;
  labs: {
    [key: string]: boolean;
  };
}

interface IDataResponse {
  data: IAccountData;
}

export interface IAppAccountData {
  id: IAccountData['id'];
  lang: IAccountData['lang'] | IAccountData['language'];
  planName: IAccountData['planName'];
  name: IAccountData['name'];
  labs: IAccountData['labs'];
  status: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export async function accountDataAdapter(): Promise<IAccountData> {
  try {
    const { data: accountData } = (await fetch({
      baseURL: URLS.API_URL,
      url: '/account',
    })) as unknown as IDataResponse;
    const { lang, language, planName, labs, id, name } = accountData;
    return {
      id: id,
      lang: lang || language,
      planName,
      labs,
      name,
    };
  } catch (error) {
    throw new Error('Failed to fetch account data');
  }
}
