import { PayloadAction } from '@reduxjs/toolkit';

import { IAppWebsiteData } from '../../adapters/websiteDataAdapter';

export const getWebsiteReducer = (
  state: IAppWebsiteData,
  { payload }: PayloadAction<IAppWebsiteData>
) => ({
  ...state,
  ...payload,
  status: 'succeeded',
});
