import { ITrigger } from '../utils/interfaces';
import { URLS } from '../utils/constants';

import { fetch } from '@/utils/fetch';
import { parseTriggerResponse } from '@/utils/parseTriggerResponse';
import { IAppWebsiteData } from '@/adapters/websiteDataAdapter';

interface DataResponseUpdateTrigger {
  data: ITrigger;
}

const updateTrigger = async (
  siteId: IAppWebsiteData['siteId'],
  dataTrigger: Partial<ITrigger>
): Promise<ITrigger> => {
  const config = {
    method: 'put',
    url: `${URLS.API_URL}/sites/${siteId ?? ''}/chatbot/triggers/${
      dataTrigger.id ?? ''
    }`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: parseTriggerResponse(dataTrigger),
  };
  try {
    const { data: triggerUpdated } = (await fetch(
      config
    )) as unknown as DataResponseUpdateTrigger;

    return triggerUpdated;
  } catch (error) {
    throw new Error('Failed to edit trigger');
  }
};

export default updateTrigger;
