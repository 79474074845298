import { isEditorEmpty } from './editorValidators';
import { validateMatchValue } from './matchValidators';

import { ITrigger } from '@/utils/interfaces';

const requiredFieldsValidator = (triggerState: ITrigger) => {
  const { response, matches } = triggerState;

  const hasError =
    isEditorEmpty(response) ||
    !matches.length ||
    matches.some((match: { value: string; type: string }) => {
      return validateMatchValue(match.value).hasError;
    });
  return hasError;
};

export { requiredFieldsValidator };
