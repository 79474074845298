import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import { getWebsiteAction, updateWebsiteAction } from '../website.slice';

import { IAppWebsiteData } from '@/adapters/websiteDataAdapter';
import updateAssignPriority, { Priority } from '@/api/updateAssignPriority';

interface IUpdateAssignPriorityAction {
  siteId: string;
  dataPriority: {
    assign_priority: Priority;
  };
}

const updateAssignPriorityAction = createAsyncThunk(
  'website/updateAssignPriority',
  async ({ siteId, dataPriority }: IUpdateAssignPriorityAction, thunkAPI) => {
    const response: Priority = await updateAssignPriority(siteId, dataPriority);
    const adaptedResponse = dataPriority.assign_priority.filter(
      (v) => v !== 'QUESTION'
    );

    thunkAPI
      .dispatch(
        updateWebsiteAction({
          siteId,
          updateWebsiteData: { assignPriority: [...adaptedResponse] },
        })
      )
      .then(unwrapResult)
      .then((res) => {
        thunkAPI
          .dispatch(getWebsiteAction(res.id))
          .then(unwrapResult)
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });

    return response;
  }
);

export const updateAssignPriorityReducer = (state: IAppWebsiteData) => ({
  ...state,
  status: 'succeeded',
});

export default updateAssignPriorityAction;
