import { ITrigger } from '../utils/interfaces';
import { URLS } from '../utils/constants';

import { fetch } from '@/utils/fetch';
import { parseTriggerResponse } from '@/utils/parseTriggerResponse';

interface DataResponsePostTrigger {
  data: ITrigger;
}

const postTrigger = async (
  siteId: string,
  dataTrigger: Partial<ITrigger>
): Promise<ITrigger> => {
  const config = {
    method: 'post',
    url: `${URLS.API_URL}/sites/${siteId}/chatbot/triggers`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: parseTriggerResponse(dataTrigger),
  };
  try {
    const { data: triggerCreated } = (await fetch(
      config
    )) as unknown as DataResponsePostTrigger;

    return triggerCreated;
  } catch (error) {
    throw new Error('Failed to Post new triggers');
  }
};

export default postTrigger;
