import { Checkbox, CheckboxGroup, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { translate } from '@/utils/i18n/translate';
import { useAppSelector } from '@/hooks/useRedux/redux';
import { IAppTrigger } from '@/utils/interfaces';

interface ActionsResponseProps {
  hasStopNextMessage: boolean;
  hasIgnoreLead: boolean;
  typeResponse: IAppTrigger['typeResponse'];
  onUpdateStopNextMessage: (value: boolean) => void;
  onUpdateIgnoreLead: (value: boolean) => void;
}

const ActionsResponse = ({
  hasStopNextMessage,
  hasIgnoreLead,
  typeResponse,
  onUpdateStopNextMessage,
  onUpdateIgnoreLead,
}: ActionsResponseProps) => {
  const [isChecked, setIsChecked] = useState(hasStopNextMessage);
  const [isCheckedIgnoreLead, setIsCheckedIgnoreLead] = useState(hasIgnoreLead);

  const { labs } = useAppSelector((state) => state.account);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'stopNextMessage') {
      setIsChecked(e.target.checked);
      onUpdateStopNextMessage(e.target.checked);
    }
    if (name === 'ignoreLead') {
      setIsCheckedIgnoreLead(checked);
      onUpdateIgnoreLead(checked);
    }
  };

  return (
    <CheckboxGroup>
      {labs?.stopchat && (
        <Checkbox
          data-testid="end_conversation"
          id="ignoreLead"
          name="ignoreLead"
          type="checkbox"
          isChecked={isCheckedIgnoreLead}
          onChange={handleOnChange}
          borderColor={'#B0B0B0'}
          mt={'10px'}
          fontSize={{
            base: '12px',
            lg: '16px',
          }}
        >
          {translate('end_conversation')}
        </Checkbox>
      )}
      <Flex direction={'column'}>
        <Checkbox
          sx={{
            '.chakra-checkbox__control': {
              base: {
                marginBottom: '1.1rem',
              },
              md: {
                marginBottom: '0rem',
              },
            },
            '.chakra-checkbox__label': {
              base: {
                fontSize: '1rem',
              },
              md: {
                fontSize: '16px',
                marginBottom: '1.1rem',
              },
            },
          }}
          data-testid="stop_next_message"
          id="stopNextMessage"
          name="stopNextMessage"
          type="checkbox"
          isChecked={typeResponse === 'button' || isChecked}
          disabled={typeResponse === 'button'}
          onChange={handleOnChange}
          borderColor={'#B0B0B0'}
          fontSize={{
            base: '12px',
            lg: '16px',
          }}
          mt={'10px'}
        >
          {translate('convex.pause_conversaton')}
        </Checkbox>
        {typeResponse === 'button' && (
          <Text
            data-testid="pause_conversation"
            mb={10}
            color={'#B0B0B0'}
            fontSize="14px"
            ml={'24px'}
          >
            {translate('lead.pause_conversaton')}
          </Text>
        )}
      </Flex>
    </CheckboxGroup>
  );
};

export default ActionsResponse;
