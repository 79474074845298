export default {
  notification_success: {
    status: 'success',
    duration: 3000,
    isClosable: true,
  },
  notification_info: {
    status: 'info',
    duration: 3000,
    isClosable: true,
  },
  notification_error: {
    status: 'error',
    duration: 3000,
    isClosable: true,
  },
  ask_delete_trigger: {
    type: 'request_confirm',
    component: 'modal',
    title: 'Eliminar respuesta personalizada',
    description:
      'Al eliminar esta respuesta personalizada, tambien se eliminaran las distintas formas de preguntar',
    rejectLabel: 'Cancelar',
    confirmLabel: 'Aceptar',
  },
};
