import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    borderRadius: 'full',
  },
  sizes: {
    md: {
      minWidth: '141px',
      fontSize: '14px',
      height: '31px',
    },
    xl: {
      fontSize: '16px',
      height: '36px',
    },
    mobile: {
      fontSize: '14px',
      height: '41px',
    },
  },
  variants: {
    primary: {
      backgroundColor: 'primary',
      color: 'white',
      _hover: {
        backgroundColor: 'primary.700',
      },
    },
    secondary: {
      backgroundColor: 'secondary',
      color: 'white',
      _hover: {
        backgroundColor: 'secondary.700',
      },
    },
    danger: {
      backgroundColor: 'danger.default',
      color: 'white',
      _hover: {
        backgroundColor: 'danger.700',
      },
    },
    outlinePrimary: {
      backgroundColor: 'transparent',
      color: 'primary',
      border: '1px solid',
      borderColor: 'primary',
    },
    outlineSecondary: {
      backgroundColor: 'transparent',
      color: 'secondary',
      border: '1px solid',
      borderColor: 'secondary',
    },
    outlineDanger: {
      backgroundColor: 'transparent',
      color: 'danger.default',
      border: '1px solid',
      borderColor: 'danger.default',
      _hover: {
        color: 'white',
        backgroundColor: 'danger.500',
        borderColor: 'danger.500',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
