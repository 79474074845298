import axios, { AxiosRequestConfig } from 'axios';

import { API_KEY, JWT } from '@/constants/environment';

export const fetch = axios.create({
  withCredentials: true,
});

if (process.env.NODE_ENV === 'development') {
  fetch.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      config.params = {
        api_key: API_KEY,
        jwt: JWT,
        ...config.params,
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
