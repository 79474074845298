import { useEffect, useState } from 'react';
import { Box, ChakraProps, Flex, chakra } from '@chakra-ui/react';

import { isDateExpired, setExpDate } from '../../utils/expDate';

interface AlertProps extends ChakraProps {
  text: string | React.ReactNode;
  color?: string;
  icon?: string;
  closable?: boolean;
  closableId?: string;
  borderless?: boolean;
  closableExpTime?: {
    number: number;
    unit: 'days' | 'hours' | 'minutes' | 'seconds';
  };
  trackCloseEvent?: () => void;
}

export const Alert = (props: AlertProps) => {
  const {
    text,
    color = 'primary',
    icon = 'icon-info-circle',
    closable = false,
    closableId,
    borderless = false,
    trackCloseEvent,
    closableExpTime,
    ...rest
  } = props;

  const [shouldRender, setShouldRender] = useState(!closableId);

  const onClose = () => {
    if (closableId) {
      let expDate = '';

      if (closableExpTime) {
        expDate = setExpDate(closableExpTime).toString();
        localStorage.setItem(closableId, expDate);
      }
      setShouldRender(false);
      trackCloseEvent?.();
    }
  };

  useEffect(() => {
    if (closableId) {
      const wasClosed =
        typeof window !== 'undefined' && localStorage.getItem(closableId);

      if (wasClosed && closableExpTime) {
        const isExpired = isDateExpired(wasClosed);

        if (isExpired) {
          localStorage.removeItem(closableId);
          setShouldRender(true);
          return;
        }
      }

      setShouldRender(!wasClosed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closableId]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      gap={4}
      bg={`${color}.25`}
      px={4}
      py={2}
      rounded="md"
      {...(borderless
        ? {}
        : {
            border: '1px solid',
            borderColor: color,
          })}
      {...rest}
    >
      <chakra.i color={color} fontSize="18px" className={`icon ${icon}`} />
      <Box>
        {typeof text === 'string' ? (
          <chakra.p
            fontSize="14px"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          text
        )}
      </Box>
      {closable && (
        <chakra.button bg="transparent" onClick={onClose}>
          <chakra.i className="icon icon-close" />
        </chakra.button>
      )}
    </Flex>
  );
};
