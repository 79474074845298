import { URLS } from '../utils/constants';

import { fetch } from '@/utils/fetch';
import { IWebsiteData } from '@/adapters/websiteDataAdapter';

interface DataResponseWebsite {
  data: IWebsiteData;
}

const updateWebsite = async (
  siteId: string,
  dataWebsite: Partial<IWebsiteData>
): Promise<IWebsiteData> => {
  const config = {
    method: 'patch',
    url: `${URLS.API_URL}/sites/${siteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: dataWebsite,
  };
  try {
    const { data: websiteUpdated } = (await fetch(
      config
    )) as unknown as DataResponseWebsite;
    return websiteUpdated;
  } catch (error) {
    throw new Error('Failed to edit website');
  }
};

export default updateWebsite;
