import { URLS } from '@/utils/constants';
import { fetch } from '@/utils/fetch';
import getWebsiteFormatType from '@/utils/getWebsiteFormatType';
export interface IWebsiteData {
  id: string;
  type?: string;
  fbPageAccessToken?: string;
  wavyAccessToken?: string;
  language?: string;
  lang?: string;
  labs: {
    [key: string]: boolean;
  };
  assignPriority:
    | ['INTERVENED', 'TRIGGER', 'ROUND_ROBIN']
    | ['TRIGGER', 'INTERVENED', 'ROUND_ROBIN'];
  autoAssign: string[];
  tags: [];
  availableSmartTriggers: string[];
}

interface DataResponse {
  data: {
    results: IWebsiteData[];
  };
}

export type WebSiteFormatType =
  | 'website'
  | 'facebook'
  | 'whatsapp'
  | 'instagram';

export interface IAppWebsiteData {
  siteId: IWebsiteData['id'];
  editorType: WebSiteFormatType;
  lang: IWebsiteData['lang'] | undefined;
  labs: IWebsiteData['labs'];
  assignPriority: IWebsiteData['assignPriority'];
  autoAssign: IWebsiteData['autoAssign'];
  tags: IWebsiteData['tags'];
  availableSmartTriggers: IWebsiteData['availableSmartTriggers'];
  status?: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export async function websiteDataAdapter(
  siteId: string
): Promise<IAppWebsiteData> {
  try {
    const { data: websiteData } = (await fetch({
      baseURL: URLS.API_URL,
      url: '/sites',
    })) as unknown as DataResponse;
    const websites = websiteData.results;
    const currentWebsite = websites.find((website) => website.id === siteId);
    if (!currentWebsite) {
      throw new Error('Failed to find website, check siteId in url');
    }
    const {
      labs,
      id,
      language,
      lang,
      assignPriority,
      autoAssign,
      tags,
      availableSmartTriggers,
    } = currentWebsite;

    return {
      siteId: id,
      editorType: getWebsiteFormatType({
        wavyAccessToken: currentWebsite?.wavyAccessToken,
        type: currentWebsite.type,
      }),
      lang: language?.slice(0, 2) || lang?.slice(0, 2),
      labs,
      assignPriority,
      autoAssign,
      tags,
      availableSmartTriggers,
    };
  } catch (error) {
    throw new Error('Failed to fetch website data');
  }
}
