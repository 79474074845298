export const parseLegacyUrlFormat = (text: string) => {
  const EMAIL_MATCHER =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  const URL_MATCHER =
    /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  const links = text.match(
    new RegExp(`${EMAIL_MATCHER.source}|${URL_MATCHER.source}`, 'g')
  );

  let html = text;

  if (links) {
    links.forEach((link) => {
      if (EMAIL_MATCHER.test(link)) {
        html = html.replace(link, `<a href="mailto:${link}">${link}</a>`);
      } else if (URL_MATCHER.test(link)) {
        html = html.replace(link, `<a href="${link}">${link}</a>`);
      }
    });
  }

  return html.replaceAll('\n', '<br/>');
};
