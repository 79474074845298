import { IAppTrigger, IMatch } from '@/utils/interfaces';

export const initialState: IAppTrigger = {
  id: null,
  enabled: true,
  name: null,
  response: '',
  stopNextMessage: false,
  assignedTo: [],
  ignoreLead: false,
  responseOptions: [],
  matches: [{ value: '', type: 'EQUALS' }],
  typeResponse: 'text',
  hasUnsaveChanges: false,
};

export type TriggerPayloadAction<TPayload> = {
  type: string;
  payload: TPayload;
};

export enum ActionType {
  UPDATE_STATUS = 'UPDATE_STATUS',
  UPDATE_NAME = 'UPDATE_NAME',
  UPDATE_RESPONSE = 'UPDATE_RESPONSE',
  UPDATE_TYPE_RESPONSE = 'UPDATE_TYPE_RESPONSE',
  UPDATE_STOP_NEXT_MESSAGE = 'UPDATE_STOP_NEXT_MESSAGE',
  UPDATE_ASSIGNED_TO_ADD = 'UPDATE_ASSIGNED_TO_ADD',
  UPDATE_ASSIGNED_TO_REMOVE = 'UPDATE_ASSIGNED_TO_REMOVE',
  UPDATE_IGNORE_LEAD = 'UPDATE_IGNORE_LEAD',
  ADD_NEW_MATCH = 'ADD_NEW_MATCH',
  UPDATE_MATCH = 'UPDATE_MATCH',
  DELETE_MATCH = 'DELETE_MATCH',
  ADD_NEW_OPTION = 'ADD_NEW_OPTION',
  DELETE_OPTION = 'DELETE_OPTION',
  RESET_OPTIONS = 'RESET_OPTIONS',
  SET_SAVED = 'SET_SAVED',
  RESET_TRIGGER = 'RESET_TRIGGER',
}

export function triggerReducer(
  state: IAppTrigger,
  action: TriggerPayloadAction<Partial<IAppTrigger>>
): IAppTrigger {
  const { type, payload } = action;
  switch (type) {
    case ActionType.UPDATE_STATUS:
      return { ...state, ...payload, hasUnsaveChanges: true };

    case ActionType.UPDATE_NAME:
      return { ...state, ...payload, hasUnsaveChanges: true };

    case ActionType.UPDATE_RESPONSE:
      return { ...state, ...payload, hasUnsaveChanges: true };

    case ActionType.UPDATE_TYPE_RESPONSE:
      return { ...state, ...payload, hasUnsaveChanges: true };

    case ActionType.UPDATE_STOP_NEXT_MESSAGE:
      return { ...state, ...payload, hasUnsaveChanges: true };

    case ActionType.UPDATE_IGNORE_LEAD:
      return { ...state, ...payload, hasUnsaveChanges: true };

    case ActionType.UPDATE_ASSIGNED_TO_ADD:
      const newAssigned = payload.assignedTo as string[];
      return { ...state, assignedTo: newAssigned, hasUnsaveChanges: true };

    case ActionType.UPDATE_ASSIGNED_TO_REMOVE:
      const assignedToRemove: string[] = payload.assignedTo as string[];

      const assignedRemoveUpdate: string[] = state.assignedTo.filter(
        (elem) => !assignedToRemove.includes(elem)
      );

      return {
        ...state,
        assignedTo: assignedRemoveUpdate,
        hasUnsaveChanges: true,
      };

    case ActionType.ADD_NEW_MATCH:
      const newMatch = payload.matches as IMatch[];
      const matchesUpdated = [...state.matches, ...newMatch];
      return { ...state, matches: matchesUpdated, hasUnsaveChanges: true };

    case ActionType.DELETE_MATCH:
      const matchesToRemove = payload.matches as IMatch[];
      const matchesNotDeleted = state.matches.filter(
        (_, i) => !matchesToRemove.some((elemArr1) => i === elemArr1.position)
      );
      return { ...state, matches: matchesNotDeleted, hasUnsaveChanges: true };

    case ActionType.UPDATE_MATCH:
      const matchesToModify = payload.matches as IMatch[];
      const modifier = (
        acum: IMatch[],
        elem: IMatch,
        index: number
      ): IMatch[] => {
        let addElem: IMatch;
        if (matchesToModify.some((elem) => elem.position === index)) {
          addElem = matchesToModify.find(
            (elem: IMatch) => elem.position === index
          ) as IMatch;
          delete addElem.position;
        } else {
          addElem = elem;
        }
        return [...acum, addElem] as IMatch[];
      };
      const reducerModified: IMatch[] = state.matches.reduce(modifier, []);
      return { ...state, matches: reducerModified, hasUnsaveChanges: true };

    case ActionType.ADD_NEW_OPTION:
      const newTags = payload.responseOptions as string[];
      const currentTagged = state.responseOptions;
      const tagsAddUpdate = Array.from(new Set([...currentTagged, ...newTags]));
      return {
        ...state,
        responseOptions: tagsAddUpdate,
        hasUnsaveChanges: true,
      };

    case ActionType.DELETE_OPTION:
      const tagsToRemove: string[] = payload.responseOptions as string[];
      const tagsRemoveUpdate: string[] = state.responseOptions.filter(
        (elem) => !tagsToRemove.includes(elem)
      );
      return {
        ...state,
        responseOptions: tagsRemoveUpdate,
        hasUnsaveChanges: true,
      };

    case ActionType.RESET_OPTIONS:
      return {
        ...state,
        responseOptions: [],
        hasUnsaveChanges: true,
      };

    case ActionType.SET_SAVED:
      return { ...state, ...payload };

    case ActionType.RESET_TRIGGER:
      return { ...state, ...payload };

    default:
      return state;
  }
}
