/* eslint-disable prettier/prettier */
export default {
  title: 'Configuración avanzada',
  description_embeded:
    'Configura palabras claves que suelen escribir tus visitantes y <b>no hayas incluido como botones de la conversación</b>, para interpretarlas y darles una respuesta',
  description_no_embeded: 'Configura palabras claves que suelen escribir tus visitantes para interpretarlas y darles una respuesta',
  info: 'Si ya creaste un botón en la conversación, el chatbot responderá correctamente cuando tu visitante presione esa opción o la escriba. No es necesario que crees una palabra clave igual. Aquí puedes crear respuestas a <b>palabras o frases que no sean parte de la conversación que hayas configurado.</b>',
  another_intervenes:
    'Si otro usuario interviene la conversación, priorizar la asignación por',
  customer_write: 'Si el cliente escribe:',
  add_another: 'Agregar',
  add_another_ask: 'Agregar palabras claves relacionadas',
  bot_will_reply: 'Entonces el bot responderá:',
  response_actions: 'Acciones de la respuesta:',
  matching_type_label: 'Tipo de concordancia:',
  matching_type_title: 'Tipos de concordancias:',
  user_assignment: 'Asignación de usuarios:',
  user_assignment_placeholder: 'Seleccionar usuario',
  select_user: 'Seleccionar usuario',
  tag: 'Tag',
  done: 'Hecho',
  discard: 'Descartar',
  assignment_priority_options: {
    trigger: 'Palabras Claves',
    intervened: 'Cliengo Live',
  },
  end_conversation:
    'Terminar la conversación después de que se dispare esta respuesta e ignorar contacto',
  notificactions: {
    trigger_created_success: 'Se ha creado una nueva palabra clave.',
    trigger_created_error: 'Hubo un error al crear.',
    trigger_updated_success: 'Has editado la palabra clave.',
    trigger_updated_error: 'Hubo un error al editar.',
    trigger_removed_success: 'Has eliminado la palabra clave.',
    trigger_removed_error: 'Hubo un error al eliminar.',
    trigger_invalid_fields_error: {
      title: 'Campos inválidos',
      desciption: 'Escriba al menos una respuesta y una forma de preguntar',
    },
    assignment_priority_updated_success:
      'Se ha editado correctamente la prioridad de asignación.',
    assignment_priority_updated_error: 'Hubo un error al asignar.',
  },
  request_confirm: {
    ask_delete_trigger: {
      title: 'Eliminar palabra clave',
      description:
        'Al eliminar esta frase o palabra clave, también se eliminarán las distintas palabras claves relacionadas.',
      rejectLabel: 'Cancelar',
      confirmLabel: 'Aceptar',
    },
  },
  delete_trigger: 'Eliminar',
  convex: {
    setup_keywords: 'Configura tus palabras claves',
    dataTrigger: {
      new_keyword: 'Nueva palabra clave',
      answer_keyword: 'Titulo para identificar la palabra clave',
      how_to_ask: 'Frase o palabras claves',
    },
    editor_placeholder: 'Respuesta a la palabra clave',
    editor_error_message: 'Debes agregar una respuesta.',
    pause_conversaton:
      'Pausar la conversación hasta el próximo mensaje del visitante',
    keywords: 'Palabras claves',
    your_keywords: 'Tus palabras claves:',
    add_new_keyword: 'Agrega una nueva palabra clave',
    matching_type_options: {
      smarts: 'Inteligente',
      contains: 'Contiene',
      equals: 'Exacta',
    },
    tooltips: {
      customer_write:
        'Agrega diferentes frases o palabras claves que tus clientes utilicen en las conversaciones. El robot entenderá cuál es la respuesta asociada. Te recomendamos agregar al menos 3 palabras claves relacionadas.',
      matching_type:
        'Exacta: La pregunta configurada debe corresponder complemente con la consulta del cliente. \n\nContiene: Si lo que se requiere es que una pregunta esté contenida en una consulta de una o varias palabras, entonces lo mejor es utilizar este modo. \n\nInteligente: Este modo es utilizado para realizar búsquedas de texto de palabras similares donde las palabras no tienen porque ser escritas completamente',
      matching_type_array: [
        '<strong>Exacta:</strong> La pregunta configurada debe corresponder complemente con la consulta del cliente.',
        '<strong>Contiene:</strong> Si lo que se requiere es que una pregunta esté contenida en una consulta de una o varias palabras, entonces lo mejor es utilizar este modo.',
        '<strong>Inteligente:</strong> Este modo es utilizado para realizar búsquedas de texto de palabras similares donde las palabras no tienen porque ser escritas completamente.',
      ],
      setup_keywords:
        'Las palabras claves, ayudan a responder las dudas de tus visitantes, aunque no estén contempladas en el árbol conversacional.',
    },
  },

  lead: {
    setup_responses: 'Configura tus palabras claves',
    dataTrigger: {
      new_response: 'Nueva palabra clave',
      answer_response: 'Título para identificar tus palabras claves',
      how_to_ask: 'Forma de preguntar',
    },
    editor_placeholder: 'Respuesta a las palabras claves',
    editor_error_message: 'Debes agregar una respuesta.',
    pause_conversaton:
      'Al activar los botones de opciones, el chatbot esperará a\n obtener una respuesta del cliente antes de continuar.',
    customized_responses: 'Palabras claves',
    your_responses: 'Tus palabras claves configuradas:',
    add_new_response: 'Agregar una nueva palabra clave',
    matching_type_options: {
      smarts: 'Inteligente',
      contains: 'Contiene',
      equals: 'Exacta',
    },
    response_type_label: 'Tipo de respuesta',
    response_type_options: {
      text: 'Texto',
      buttons: 'Botones',
      options: 'Opciones (máximo 12)',
      placeholder: 'Agregar nueva opción',
    },
    tooltips: {
      customer_write:
        'Agrega diferentes frases o palabras claves que tus clientes utilicen en las conversaciones. El robot entenderá cuál es la respuesta asociada. Te recomendamos agregar al menos 3 palabras claves relacionadas.',
      matching_type:
        'Exacta: La pregunta configurada debe corresponder complemente con la consulta del cliente. \n\nContiene: Si lo que se requiere es que una pregunta esté contenida en una consulta de una o varias palabras, entonces lo mejor es utilizar este modo. \n\nInteligente: Este modo es utilizado para realizar búsquedas de texto de palabras similares donde las palabras no tienen porque ser escritas completamente',
      matching_type_array: [
        '<strong>Exacta:</strong> La pregunta configurada debe corresponder complemente con la consulta del cliente.',
        '<strong>Contiene:</strong> Si lo que se requiere es que una pregunta esté contenida en una consulta de una o varias palabras, entonces lo mejor es utilizar este modo.',
        '<strong>Inteligente:</strong> Este modo es utilizado para realizar búsquedas de texto de palabras similares donde las palabras no tienen porque ser escritas completamente.',
      ],
      setup_responses:
        'Crea respuestas a palabras claves específicas de tu negocio que los visitantes hacen en tu sitio.',
    },
    validation_messages: {
      matchs: {
        noEmpty: 'Debes agregar una palabra clave.',
        tooLong: 'Ups! Las palabras claves permiten hasta 20 carácteres',
        tooShort: 'Las palabras claves deben tener al menos 4 carácteres',
      },
      typeResponse: {
        noEmpty: 'La opción debe contener carácteres.',
        isDuplicated:
          'El texto de la opción está duplicado, intenta reemplazando el texto.',
        limit_options: 'Has llegado al límite de opciones permitidas.',
      },
    },
  },
  smart_triggers: {
    title_suggested_response: 'Palabras claves sugeridas',
    subtitle_suggested_response:
      'Agrega palabras claves sugeridas por <strong>inteligencia artificial</strong> en base al análisis de tus conversaciones con clientes.',
    reply_to: 'Respuesta a:',
    add: 'Agregar',
    ignore: 'Ignorar',
    error:
      'Se produjo un error, es posible que las sugerencias no esten disponible.',
    error_suggestion_already_selected: 'Para poder agregar una palabra clave sugerida debes terminar de configurar tu palabra clave actual.'
  },
};
