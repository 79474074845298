import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import {
  ITriggersList,
  ITrigger,
  IAppTrigger,
} from '../../../utils/interfaces';
import { setSucceededStatus } from '../../reducers/setActionStatusReducer';

import updateTrigger from '@/api/updateTrigger';

interface IUpdateTriggerData {
  siteId: string;
  dataTrigger: Partial<ITrigger>;
}

const updateTriggerAction = createAsyncThunk(
  'trigger/updateTrigger',
  async ({ siteId, dataTrigger }: IUpdateTriggerData) => {
    const response: ITrigger = await updateTrigger(siteId, dataTrigger);
    return response;
  }
);

export const updateTriggerReducer = (
  state: ITriggersList,
  action: PayloadAction<IAppTrigger>
) => {
  const { id } = action.payload;

  const resultsUpdated = state.results.map((item) => {
    if (item.id === id) {
      return { ...action.payload };
    }
    return item;
  });

  state.results = resultsUpdated;
  setSucceededStatus(state);
};

export default updateTriggerAction;
