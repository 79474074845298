import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useStartAppData } from '@/hooks/useStartAppData';
import { useAppSelector } from '@/hooks/useRedux/redux';
import Splash from '@/components/Loader/Splash.component';

interface IInitialDataChecker {
  children: React.ReactNode;
}

const InitialDataChecker: FC<IInitialDataChecker> = ({ children }) => {
  const { siteId } = useParams();

  const { loading, error } = useAppSelector((state) => state.layout);

  useStartAppData(siteId as string);

  if (error) {
    return <div>Error</div>;
  }

  return <>{loading ? <Splash /> : children}</>;
};

export default InitialDataChecker;
