/* eslint-disable prettier/prettier */
export default {
  title: 'Configuración avanzada',
  description_embeded:
    'Configura palabras claves que suelen escribir tus visitantes y <b>no hayas incluido como botones de la conversación</b>, para interpretarlas y darles una respuesta personalizada',
  description_no_embeded: 'Configura palabras claves que suelen escribir tus visitantes para interpretarlas y darles una respuesta personalizada',
  info: 'Si ya creaste un botón en la conversación, el chatbot responderá correctamente cuando tu visitante presione esa opción o la escriba. No es necesario que crees una respuesta personalizada igual. Aquí puedes crear respuestas a <b>palabras o frases que no sean parte de la conversación que hayas configurado.</b>',
  another_intervenes:
    'Se outro usuário intervém na conversa, priorize a tarefa por:',
  customer_write: 'Se o cliente escreve:',
  add_another: 'Adicione outro',
  add_another_ask: 'Adicionar forma de perguntar',
  bot_will_reply: 'O bot então responderá:',
  response_actions: 'Ações de resposta:',
  matching_type_label: 'Tipo de concordância:',
  matching_type_title: 'Tipos de concordância:',
  user_assignment: 'Selecione o usuário:',
  user_assignment_placeholder: 'Selecione o usuário',
  select_user: 'Selecione o usuário',
  tag: 'Marcado',
  done: 'Dado',
  discard: 'Descarte',
  assignment_priority_options: {
    trigger: 'Respostas personalizadas',
    intervened: 'Cliengo Live',
  },
  end_conversation:
    'Pare a conversação depois que este gatilho seja acionado e o contato seja ignorado',
  notificactions: {
    trigger_created_success: 'A resposta rápida foi criada.',
    trigger_created_error: 'Houve um erro enquanto salvar.',
    trigger_updated_success: 'Você editou a resposta rápida.',
    trigger_updated_error: 'Houve um editou enquanto salvar.',
    trigger_removed_success: 'Você removeu a resposta rápida.',
    trigger_removed_error: 'Houve um removeu enquanto salvar.',
    trigger_invalid_fields_error: {
      title: 'Campos inválidos',
      desciption: 'Escreva pelo menos uma resposta e uma forma de perguntar',
    },
    assignment_priority_updated_success:
      'A prioridade de atribuição foi editada corretamente',
    assignment_priority_updated_error: 'Ocorreu um erro de atribuição.',
  },
  request_confirm: {
    ask_delete_trigger: {
      title: 'Removeu resposta rápida',
      description:
        'Ao excluir esta palavra-chave de resposta personalizada, as diferentes formas de perguntar também serão excluídas.',
      rejectLabel: 'Cancelar',
      confirmLabel: 'Ok',
    },
  },
  delete_trigger: 'Removeu',
  convex: {
    setup_keywords: 'Defina suas palavras-chave',
    dataTrigger: {
      new_keyword: 'Nova palavra-chave',
      answer_keyword: 'Resposta à palavra-chave',
      how_to_ask: 'Como perguntar:',
    },
    editor_placeholder: 'Resposta de palavra-chave',
    editor_error_message: 'Você deve adicionar uma resposta.',
    pause_conversaton: 'Pausa a conversa até a próxima mensagem do visitante.',
    keywords: 'palavras-chave',
    your_keywords: 'Suas palavras-chave:',
    add_new_keyword: 'Acrescentar uma nova palavra-chave',
    matching_type_options: {
      smarts: 'Inteligente',
      contains: 'Contém',
      equals: 'Exatamente',
    },
    tooltips: {
      customer_write:
        'Acrescentar maneiras diferentes de fazer a mesma pergunta. O robô entenderá melhor qual é a resposta associada. Recomendamos adicionar pelo menos 3 maneiras de perguntar.',
      matching_type:
        'Exato: A pergunta configurada deve corresponder totalmente à consulta do cliente. \n\nContém: Se for necessário que uma pergunta seja contida em uma consulta de uma ou várias palavras, então este modo é melhor utilizado. \n\nInteligente: Este modo é usado para realizar buscas de texto por palavras similares onde as palavras não precisam ser completamente soletradas.',
      matching_type_array: [
        '<strong>Exato:</strong> A pergunta configurada deve corresponder totalmente à consulta do cliente.',
        '<strong>Contém:</strong> Se for necessário que uma pergunta seja contida em uma consulta de uma ou várias palavras, então este modo é melhor utilizado.',
        '<strong>Inteligente:</strong> Este modo é usado para realizar buscas de texto por palavras similares onde as palavras não precisam ser completamente soletradas.',
      ],
      setup_keywords:
        'As palavras-chave ajudam a responder às perguntas de seus visitantes, mesmo que não estejam incluídas na árvore de conversação.',
    },
  },

  lead: {
    setup_responses: 'Configurar suas respostas personalizadas',
    dataTrigger: {
      new_response: 'Nova palavra-chave',
      answer_response: 'Resposta à pergunta personalizada',
      how_to_ask: 'Como perguntar:',
    },
    editor_placeholder: 'Resposta à pergunta personalizada',
    editor_error_message: 'Você deve adicionar uma resposta.',
    pause_conversaton:
      'Ao ativar os botões de opção, o chatbot aguardará\n uma resposta do cliente antes de continuar',
    customized_responses: 'Respostas personalizadas',
    your_responses: 'Suas respostas personalizadas:',
    add_new_response: 'Adicionar uma nova resposta personalizada',
    matching_type_options: {
      smarts: 'Inteligente',
      contains: 'Contém',
      equals: 'Exatamente',
      regex: 'Regex (Avançado)',
    },
    response_type_label: 'Tipo de resposta',
    response_type_options: {
      text: 'Texto',
      buttons: 'Botões',
      options: 'Opções (máximo 12)',
      placeholder: 'Adicionar nova opção',
    },
    tooltips: {
      customer_write:
        'Acrescentar maneiras diferentes de fazer a mesma pergunta. O robô entenderá melhor qual é a resposta associada. Recomendamos adicionar pelo menos 3 maneiras de perguntar.',
      matching_type:
        'Exato: A pergunta configurada deve corresponder totalmente à consulta do cliente. Contém: Se for necessário que uma pergunta seja contida em uma consulta de uma ou várias palavras, então este modo é melhor utilizado. Inteligente: Este modo é usado para realizar buscas de texto por palavras similares onde as palavras não precisam ser completamente soletradas. Regex: Usado para programação de expressões regulares. Esta opção só deve ser usada se você tiver habilidades de programação.',
      matching_type_array: [
        '<strong>Exato:</strong> A pergunta configurada deve corresponder totalmente à consulta do cliente.',
        '<strong>Contém:</strong> Se for necessário que uma pergunta seja contida em uma consulta de uma ou várias palavras, então este modo é melhor utilizado.',
        '<strong>Inteligente:</strong> Este modo é usado para realizar buscas de texto por palavras similares onde as palavras não precisam ser completamente soletradas.',
        '<strong>Regex:</strong> Usado para programação de expressões regulares. Esta opção só deve ser usada se você tiver habilidades de programação.',
      ],
      setup_responses:
        'Crie respostas a perguntas específicas de negócios que os visitantes fazem em seu site.',
    },
    validation_messages: {
      matchs: {
        noEmpty: 'Você deve incluir uma resposta.',
        tooLong: 'Oops! Perguntar como permitir até 20 caracteres.',
        tooShort: 'O modo de perguntar deve ter pelo menos 4 caracteres.',
      },
      typeResponse: {
        noEmpty: 'Opção deve conter caracteres.',
        isDuplicated:
          'O texto da opção foi duplicado, tente substituir o texto.',
        limit_options: 'Você atingiu o limite de opções permitidas.',
      },
    },
  },

  smart_triggers: {
    title_suggested_response: 'Adicionar respostas sugeridas AI',
    subtitle_suggested_response:
      'Analisado por nosso modelo de inteligência artificial com base em suas conversas com os clientes.',
    reply_to: 'Resposta a:',
    add: 'Add',
    ignore: 'Ignore',
    error:
      'Ocorreu um erro, as sugestões inteligentes podem não estar disponíveis.',
  },
};
